/* eslint-disable no-unused-vars */
import * as PIXI from "pixi.js";
// import PIXISpine from "Plugins/pixi-spine"; // need to import for setup PIXI.Loader.registerPlugins();
// import { MonokaiLogColor } from "Plugins/monokai-color";
// import * as PIXIParticle from "pixi-particles";
// import EnvUrl from "Components/EnvUrl";
import { ScenarioManager } from "Classes/Scenario";
import { LobbyScenarioManager } from "Classes/LobbyScenario";
import Cache from "Classes/Cache";
import CharacterList from "./Characters/Data";
import Level from "Game/PixiCores/Levels/Data";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const GameContent = (app, gameRef, audioRef, updateRatioRef) => {
  // const loader = new PIXI.Loader();
  // const loader = app.loader;

  const gameData = {
    cacheCharacters: new Cache(),
    activeCharacter: null,
    lobbyCharacterList: [],
    allCharacterList: [],
    characterPool: {},
  };
  const cache = {
    characterPlayers: new Cache(),
    characterEnemies: new Cache(),
    characterBosses: new Cache(),
  };

  const containerMain = new PIXI.Container();
  const containerLobby = new PIXI.Container();
  const containerGameplay = new PIXI.Container();
  const containerGameplayCamera = new PIXI.Container();
  const containerLeaderboard = new PIXI.Container();

  const containerLobbyCharacter = new PIXI.Container();
  const containerLobbyBackground = new PIXI.Container();
  const containerGameplayCharacter = new PIXI.Container();
  const containerGameplayBackground = new PIXI.Container();
  const containerLeaderboardCharacter = new PIXI.Container();
  const containerLeaderboardBackground = new PIXI.Container();

  containerLobby.addChild(containerLobbyBackground);
  containerLobby.addChild(containerLobbyCharacter);
  containerLobbyCharacter.position.y = -100;

  containerGameplay.addChild(containerGameplayBackground);
  containerGameplay.addChild(containerGameplayCharacter);
  containerGameplayCamera.addChild(containerGameplay);

  // containerMain.addChild(containerGameplay);
  containerMain.addChild(containerLobby);
  containerMain.addChild(containerGameplayCamera);
  containerMain.addChild(containerLeaderboard);

  containerLeaderboard.addChild(containerLeaderboardBackground);
  containerLeaderboard.addChild(containerLeaderboardCharacter);
  containerLeaderboardCharacter.position.y = -100;

  app.stage.addChild(containerMain);
  containerGameplay.visible = false;

  const containerMockupBackground = new PIXI.Container();
  containerMain.addChild(containerMockupBackground);

  // //=====================================================================================================
  // const isOneQuestionOnly = false;
  // const bossWalked = false;

  const loadInitialCharacter = async (characterID, path) => {
    const constructControllerFromModule = async (module) => {
      return await module.default.initial(app, path);
    };

    try {
      const newModule = CharacterList[characterID];
      return await constructControllerFromModule(newModule);
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  };

  const loadCharacter = (characterID, path) => {
    const constructControllerFromModule = (module) => {
      return module.default.create(app, audioRef, path, app.loader);
    };

    return new Promise((resolve, reject) => {
      if (cache.characterPlayers.exist(characterID)) {
        // console.log("cache.characterPlayers", cache.characterPlayers);
        const cacheModule = cache.characterPlayers.get(characterID);
        const controller = constructControllerFromModule(cacheModule);
        resolve(controller);
      } else if (CharacterList[characterID]) {
        // console.log("MY CHARACTOR MODULE =>", CharactorList[characterID]);
        try {
          const newModule = CharacterList[characterID];
          cache.characterPlayers.add(characterID, newModule);
          const controller = constructControllerFromModule(newModule);
          resolve(controller);
        } catch (error) {
          Sentry.captureException(error);
          reject(error);
        }
        // import(`${CharacterData[characterID]}`)
        //   .then((newModule) => {
        //     cache.characterPlayers.add(characterID, newModule);
        //     const controller = constructControllerFromModule(newModule);
        //     resolve(controller);
        //   })
        //   .catch((e) => {
        //     console.log("ERROR HERE", e);
        //     reject(e);
        //   });
      } else {
        reject(new Error("Cannot find any character with id: " + characterID));
      }
    });
  };
  const loadLevel = (levelName, assets) => {
    return new Promise(async (resolve, reject) => {
      try {
        const newLevel = Level[levelName];
        const level = await newLevel.default.load({ app, assets });
        resolve(level);
      } catch (error) {
        Sentry.captureException(error);
        reject(error);
      }
    });
  };

  const onMainResize = (width, height) => {
    containerMain.position.set(width / 2, height);
    containerMain.scale.set(width / 1920, height / 1080);
  };

  const addNameLabelCharacter = (character, name, userID) => {
    const playerName = new PIXI.Text(name, {
      fill: "white",
      padding: 10,
      fontSize: 18,
    });
    playerName.anchor.set(0.5);

    const graphics = new PIXI.Graphics();
    graphics.beginFill(0x000000);
    graphics.drawRoundedRect(
      -(playerName.width + 32) / 2,
      -(playerName.height + 20) / 2,
      playerName.width + 32,
      playerName.height + 20,
      12
    );
    graphics.endFill();
    graphics.alpha = 0.1;
    const rectContainer = new PIXI.Container();
    rectContainer.addChild(graphics);
    rectContainer.pivot.set(0.5);

    const playerNameContainer = new PIXI.Container();
    playerNameContainer.name = "label";
    playerNameContainer.addChild(rectContainer);
    playerNameContainer.addChild(playerName);
    playerNameContainer.id = userID;
    character.container.addChild(playerNameContainer);
    playerNameContainer.position.set(0, 40);
    character.container.userID = userID;
  };

  updateRatioRef.current.add(onMainResize);
  const onRelease = () => {
    updateRatioRef.current.remove(onMainResize);
    console.warn("pixi released");
  };

  const settingCharacter = (spineData) => {
    spineData.container.name = spineData.userID;
    containerGameplayCharacter.addChild(spineData.container);
    spineData.setActive(false);
    spineData.container.position.set(-576, -108);

    addNameLabelCharacter(spineData, spineData?.userName, spineData?.userID);
    return spineData;
  };

  const rejectCharacter = (userData) => {
    // console.log("reject work");
    const removeIndex = containerGameplayCharacter.children.findIndex(
      (child) => child.name === userData.userID
    );

    containerGameplayCharacter.removeChildAt(removeIndex);
    // console.log(containerGameplayCharacter.children);
    // containerGameplayCharacter.getChildAt
  };

  const lobbyScenario = new LobbyScenarioManager({
    app,
    containerLevel: containerLobbyBackground,
    containerStage: containerLobbyCharacter,
  });

  const gameplayScenario = new ScenarioManager({
    app,
    containerLevel: containerGameplayBackground,
    containerStage: containerGameplayCharacter,
    settingCharacter,
    rejectCharacter,
  });

  const onUpdateScenario = (ticker) => {
    return () => {
      let deltaTime = (1 / ticker.FPS) * ticker.speed;
      lobbyScenario.update(deltaTime);
      gameplayScenario.update(deltaTime);
    };
  };

  const onUpdateScene = onUpdateScenario(app.ticker);
  app.ticker.add(onUpdateScene);

  // let isEnd = false;
  // const onTestTranslateScreen = (ticker) => {
  //   return () => {
  //     let deltaTime = (1 / ticker.FPS) * ticker.speed;
  //     // if (containerGameplayBackground.position.x > -2240)
  //     //   containerGameplayBackground.position.x -= 10;
  //     if (containerMockupBackground.position.x > -3040 && !isEnd) {
  //       containerMockupBackground.position.x -= 5;
  //     } else {
  //       isEnd = true;
  //       containerMockupBackground.position.x += 5;
  //     }
  //     if (containerMockupBackground.position.x < 300 && isEnd) {
  //       containerMockupBackground.position.x += 5;
  //     } else {
  //       isEnd = false;
  //       containerMockupBackground.position.x -= 5;
  //     }
  //   };
  // };

  // const onTestTranslate = onTestTranslateScreen(app.ticker);
  // app.ticker.add(onTestTranslate);

  //#region GameRef
  if (!gameRef.current) gameRef.current = {};

  gameRef.current.addCharacterToLobby = async ({
    characterID,
    characterPath,
    userID,
    userName,
    timeStamp,
  }) => {
    // add user list.
    let characterData = {
      userID,
      userName,
      id: characterID,
      path: characterPath,
      timeStamp: timeStamp,
    };
    // lobbyScenario.pushCharacterList(characterData)

    const removeCharacterLobby = () => {
      containerLobbyCharacter.removeChildAt(0);
      gameData.lobbyCharacterList.shift();
    };

    const addCharacterLobby = (character) => {
      gameData.lobbyCharacterList.push({
        ...character,
        ...characterData,
      });
      addNameLabelCharacter(character, userName, userID);
      character.setActive(true);

      containerLobbyCharacter.addChild(character.container);
      character.container.position.set(-1000, 0);
      character.playState("idle");
    };

    return await loadCharacter(characterID, characterPath)
      .then(async (character) => {
        // const lobbyCharacterList = { ...gameData?.lobbyCharacterList };
        if (gameData.lobbyCharacterList?.length >= 8) {
          addCharacterLobby(character);
          lobbyScenario.pushCharacterList({
            ...character,
            ...characterData,
          });
          removeCharacterLobby();
        } else {
          addCharacterLobby(character);
          lobbyScenario.pushCharacterList({
            ...character,
            ...characterData,
          });
        }

        return character;
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });
  };

  // gameData.characterList.push(characterData);
  // lobbyScenario.pushCharacterList(characterData);

  // console.log(
  //   "gameData.characterList.find((player) => player.userID === userID)",
  //   gameData.characterList.find((player) => player.userID === userID)
  // );
  // if (gameData.characterList.find((player) => player.userID === userID)) {
  //   // console.log("already add this character");
  //   gameRef.current.removeCharacterFromLobby(userID);
  // }

  // if (gameData.characterList.length < 12) {
  //   gameData.characterList.push(characterData);
  //   const character = await loadCharacter(characterID, characterPath);
  //   addNameLabelCharacter(character, userName, userID);
  //   lobbyScenario.pushCharacterList({
  //     ...character,
  //     userID,
  //     userName,
  //     timeStamp,
  //   });
  //   containerLobbyCharacter.addChild(character.container);
  //   character.container.position.set(-960, 0);
  //   character.playState("idle");
  // } else {
  // }

  // if (gameData.characterList < 12) {
  // }

  // return await loadCharacter(characterID, characterPath)
  //   .then((character) => {
  //     addNameLabelCharacter(character, userName, userID);

  //     containerLobbyCharacter.addChild(character.container);
  //     character.container.position.set(-960, 0);
  //     character.playState("idle");

  //     // gameData.characterList.push({
  //     //   ...character,
  //     //   userID,
  //     //   userName,
  //     //   // timeStamp,
  //     // });

  //     lobbyScenario.pushCharacterList({
  //       // ...character,
  //       userID,
  //       userName,
  //       // timeStamp,
  //     });

  //     // if (gameData.characterList.length > 15) {
  //     //   gameRef.current.removeCharacterFromLobby(
  //     //     gameData.characterList[0].userID
  //     //   );
  //     // }
  //     return character;
  //   })
  //   .catch((e) => {
  //     console.error(e);
  //   });

  gameRef.current.removeCharacterFromLobby = (userID) => {
    gameData.lobbyCharacterList = gameData.lobbyCharacterList.filter(
      (character) => {
        return character.userID !== userID;
      }
    );
    // gameData?.lobbyCharacterList.filter((user) => user?.userID === userID);
    // console.log(gameData?.lobbyCharacterList, userID);
    containerLobbyCharacter.removeChild(
      containerLobbyCharacter.children.find((container) => {
        return container.userID === userID;
      })
    );
    lobbyScenario.removeCharacterFromList(userID);
  };

  gameRef.current.removeCharacterFromGameplay = (userID) => {
    gameData.allCharacterList = gameData.allCharacterList.filter(
      (character) => {
        return character.userID !== userID;
      }
    );
    containerGameplayCharacter.removeChild(
      containerGameplayCharacter.children.find((container) => {
        return container.userID === userID;
      })
    );
    gameplayScenario.removeCharacterFromList(userID);
  };

  gameRef.current.initialLobbyScenario = async () => {
    const characterListContainer = gameData.lobbyCharacterList;
    lobbyScenario.initialize(characterListContainer);
    lobbyScenario.update();
    // return await gameRef.current.loopWalking();
  };

  gameRef.current.pushCharacterList = (character) => {
    lobbyScenario.pushCharacterList(character);
  };

  gameRef.current.loopWalking = async (userID, timeStamp) => {
    return await lobbyScenario.triggerWalkCharacter(userID, timeStamp);
  };

  gameRef.current.resetLobby = () => {
    gameData.lobbyCharacterList = [];
    containerLobbyCharacter.removeChildren();
    lobbyScenario.reset();
  };

  gameRef.current.isReadyToPlay = false;

  gameRef.current.addCharacterToGameplay = async ({
    characterID,
    characterPath,
    userID,
    userName,
  }) => {
    if (gameData.allCharacterList.find((player) => player.userID === userID)) {
      // console.log("already add this character");
      gameRef.current.removeCharacterFromGameplay(userID);
    }

    let characterData = {
      userID,
      userName,
      id: characterID,
      path: characterPath,
    };

    gameData.allCharacterList.push(characterData);
    gameplayScenario.updateCharacterList(userID, characterData);

    // return  await loadCharacter(characterID, characterPath)
    //   .then((character) => {
    //     // character.spine.scale.set(0.1);
    //     // character.playState("idle");

    //     // addNameLabelCharacter(character, userName, userID);
    //     // containerGameplayCharacter.addChild(character.container);
    //     // character.setActive(false);
    //     // character.container.position.set(-576, -108);

    //     let characterData = {
    //       // ...character,
    //       userID,
    //       userName,
    //       id: characterID,
    //       path: characterPath,
    //     };

    //     gameData.allCharacterList.push(characterData);
    //     // gameData.characterPool.push(character);

    //     console.log("gameData.allCharacterList", gameData.allCharacterList);
    //     console.log("gameData.characterPool", gameData.characterPool);

    //     // console.log(
    //     //   "addcharacter to gameplay gameData.allCharacterList",
    //     //   gameData.allCharacterList
    //     // );

    //     gameplayScenario.updateCharacterList(userID, characterData);
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  // gameRef.current.updateCharacterToGameplay = async ({
  //   characterID,
  //   characterPath,
  //   userID,
  //   userName,
  // }) => {
  //   // console.log(
  //   //   "content : gameData.allCharacterList",
  //   //   gameData.allCharacterList
  //   // );
  //   // let character = gameData.allCharacterList.filter(
  //   //   (character) => character.userID === userID
  //   // );
  //   // console.log("character filter ", character);
  //   // gameplayScenario.updateCharacterList(userID, character);
  // };

  gameRef.current.initialGamaplayScenario = async ({
    levelName,
    assets,
    characterData,
    bossID,
    bossPath,
    enemiesData,
  }) => {
    lobbyScenario.reset();
    gameplayScenario.reset();
    containerGameplay.visible = true;
    const loadAllCharacter = async (characterList) => {
      const promises = characterList.map(async (character) => {
        return await loadCharacter(character.id, character.path);
      });
      return await Promise.all(promises);
    };

    // const loadAllPlayerCharacter = async (characterList) => {
    //   const promises = characterList.map(async (character) => {
    //     const controller = await loadCharacter(character.id, character.path);
    //     controller.userID = character.userID;
    //     controller.name = character.name;
    //     return controller;
    //   });
    //   return await Promise.all(promises);
    // };

    // console.log(gameData.allCharacterList);

    // const loadFirstGroupPlayer = async () => {
    //   let first5PlayerCharacter = [];

    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       for (
    //         let index = 0;
    //         index <
    //         (gameData.allCharacterList.length >= 5
    //           ? 5
    //           : gameData.allCharacterList.length);
    //         index++
    //       ) {
    //         const userData = gameData.allCharacterList.find(
    //           (character) => character.userID === characterData[index].userID
    //         );

    //         const character = await loadCharacter(userData.id, userData.path);
    //         const playerData = { ...userData, ...character };

    //         containerGameplayCharacter.addChild(playerData.container);
    //         playerData.setActive(false);
    //         playerData.container.position.set(-576, -108);

    //         first5PlayerCharacter.push(playerData);

    //         addNameLabelCharacter(
    //           playerData,
    //           playerData?.userName,
    //           playerData?.userID
    //         );
    //         resolve(first5PlayerCharacter);
    //       }
    //     } catch (error) {
    //       reject(error);
    //     }
    //   });
    // };

    const loadCharacterPool = async () => {
      const allCharacterPath = gameData.allCharacterList.reduce(
        (prev, userData) => {
          return prev[userData.id]
            ? {
                ...prev,
                [userData.id]: {
                  id: userData.id,
                  path: userData.path,
                  pool: prev[userData.id].pool + 1,
                },
              }
            : {
                ...prev,
                [userData.id]: {
                  id: userData.id,
                  path: userData.path,
                  pool: 1,
                },
              };
        },
        {}
      );

      // console.log("allCharacterPath", allCharacterPath);

      return new Promise(async (resolve, reject) => {
        try {
          const characterPool = await Object.entries(allCharacterPath).reduce(
            async (prev, [_, characterData]) => {
              const memo = await prev;

              // console.log(characterData);

              let characterPoolArray = [];
              for (
                let index = 0;
                index < (characterData.pool > 5 ? 5 : characterData.pool);
                index++
              ) {
                const character = await loadCharacter(
                  characterData.id,
                  characterData.path
                );

                characterPoolArray.push({
                  ...character,
                  poolIndex: index,
                  isSpineActive: true,
                  owner: "",
                });
              }

              // console.log(characterPoolArray);

              return { ...memo, [characterData.id]: characterPoolArray };
            },
            {}
          );

          resolve(characterPool);
        } catch (error) {
          reject(error);
        }
      });
    };

    const promises = [
      loadLevel(levelName, assets),
      loadCharacter(bossID, bossPath),
      loadAllCharacter(enemiesData),
      // loadAllPlayerCharacter(characterData),
      // loadFirstGroupPlayer(),
      loadCharacterPool(),
    ];

    return Promise.all(promises)
      .then(
        async ([
          level,
          boss,
          enemies,
          // firstGroupPlayer,
          characterPool,
          // playerCharacter,
          // character, boss, enemy
        ]) => {
          // console.log("initial gameplay");

          containerGameplayBackground.addChild(level.parallax.container);
          containerGameplayBackground.position.set(-330, 0);

          containerGameplayCharacter.addChild(boss.container);
          boss.setActive(true);
          boss.container.position.set(1920 * 1, -108);
          boss.playState("idle");

          enemies.forEach((enemy, index) => {
            // console.log("enemy", enemy);
            containerGameplayCharacter.addChild(enemy.container);
            enemy.setActive(true);
            enemy.container.position.set(
              1920 + Math.random() * 1000 - 500,
              -108
            );
            // enemy.container.position.set(1920 + (index + 1) * 2, -108);
            // enemy.spine.scale.set(0.1);
            enemy.playState("idle");
          });

          // console.log("characterPool game content", characterPool);

          // console.log("initialed enemies");

          // gameData.allCharacterList.forEach((character) => {
          //   console.log(character);

          //   // containerGameplayCharacter.addChild(character.container);
          //   // character.setActive(false);
          //   // character.container.position.set(-576, -108);
          //   // character.spine.scale.set(0.1);
          // });

          // characterData.forEach((data) => {
          //   first5PlayerCharacter.push(
          //     gameData.allCharacterList.find(
          //       (character) => character.userID === data.userID
          //     )
          //   );
          // });
          let first5PlayerCharacter = [];
          for (
            let index = 0;
            index <
            (gameData.allCharacterList.length >= 5
              ? 5
              : gameData.allCharacterList.length);
            index++
          ) {
            const userData = gameData.allCharacterList.find(
              (character) => character.userID === characterData[index].userID
            );
            const playerData = { ...userData };
            first5PlayerCharacter.push(playerData);
          }

          // console.log("first5PlayerCharacter", firstGroupPlayer);
          // console.log("gameData.allCharacterList", gameData.allCharacterList);

          let stage = containerGameplayCharacter;
          gameplayScenario.initialize(
            level,
            first5PlayerCharacter,
            enemies,
            boss,
            gameData.allCharacterList,
            stage,
            characterPool
          );
          gameplayScenario.update();
        }
      )
      .catch((e) => {
        Sentry.captureException(e);
        console.error(e);
      });
  };
  //#endregion
  if (!gameRef.current.gameplay) gameRef.current.gameplay = {};
  gameRef.current.gameplay.startScenario = async (screenWidth, isBossFight) => {
    // console.log("start!!");
    let prevPos = containerGameplay.position.x;
    await gameplayScenario.firstPlayerMove().then(() => {
      new Tweener(app.ticker)
        .insert(
          0.5,
          new Tween(1, (pos, dt) => {
            if (pos === 1) {
              prevPos = containerGameplay.position.x;
            } else {
              containerGameplay.position.x = pos * -1920 + prevPos;
            }
          })
        )
        .insert(
          2,
          new Tween(1, (pos, dt) => {
            containerGameplayCamera.scale.set(pos * 0.4 + 1, pos * 0.4 + 1);
          })
        )
        .insert(
          9,
          new Tween(1, (pos, dt) => {
            containerGameplayCamera.scale.set(
              pos * -0.4 + 1.4,
              pos * -0.4 + 1.4
            );
          })
        )
        .insert(
          10,
          new Tween(1.5, (pos, dt) => {
            containerGameplay.position.x = pos * 1920 + prevPos;
            if (pos === 1) {
              containerGameplay.scale.set(pos * 0 + 1, pos * 0 + 1);
            }
          })
        )
        .play();
    });
    setTimeout(() => {
      gameplayScenario.scenarioBossSay();
    }, 3500);
    setTimeout(async () => {
      await gameplayScenario.firstPlayerMoveToCastle();
    }, 12000);
    if (gameRef.current.isOneQuestionOnly) {
      let isOneQuestion = true;
      setTimeout(async () => {
        await gameplayScenario.playerMoveOutCastle();
        await gameplayScenario.scenarioBossWalkInStage(isOneQuestion);
      }, 13000);
    } else {
      setTimeout(async () => {
        await gameplayScenario.playerMoveOutCastle();
        await gameplayScenario.enemyMoveIn();
      }, 13000);
    }
  };

  gameRef.current.gameplay.endUserAnswer = (userID) => {
    gameplayScenario.checkTopPlayerAnswer(userID);
  };

  gameRef.current.gameplay.scenarioAttack = async (oldTopEndUser) => {
    // console.log("scenarioAttack");
    return await gameplayScenario.scenarioAttack(oldTopEndUser);
  };

  gameRef.current.gameplay.scenarioAttackBoss = async (oldTopEndUser) => {
    // console.log("scenarioAttack");
    return await gameplayScenario.scenarioAttackBoss(oldTopEndUser);
  };

  // const LoadNewSetCharacter = async (userDataArray) => {
  //   let newSetCharacter = [];

  //   for (let index = 0; index < userDataArray?.length; index++) {
  //     const character = await loadCharacter(
  //       userDataArray[index].id,
  //       userDataArray[index].path
  //     );
  //     const playerData = { ...userDataArray[index], ...character };

  //     containerGameplayCharacter.addChild(playerData.container);
  //     playerData.setActive(false);
  //     playerData.container.position.set(-576, -108);
  //     addNameLabelCharacter(
  //       playerData,
  //       playerData?.userName,
  //       playerData?.userID
  //     );

  //     newSetCharacter.push(playerData);
  //   }
  //   console.log("newSetCharacter", newSetCharacter);
  //   return newSetCharacter;
  // };

  gameRef.current.gameplay.switchPosition = async (topEndUser) => {
    // console.log("gameData.allCharacterList", gameData.allCharacterList);
    // console.log("topEndUser switchPosition", topEndUser);

    // const newPlayerSet = topEndUser.map((user) => {
    //   return gameData.allCharacterList.find(
    //     (character) => character?.userID === user?.userID
    //   );
    // });

    // console.log("newPlayerSet", newPlayerSet);

    // const newTopEndUser = await LoadNewSetCharacter(newPlayerSet);

    // console.log("newTopEndUser", newTopEndUser);

    return await gameplayScenario.scenarioSwitchPosition(topEndUser);
  };
  gameRef.current.gameplay.spawnEnemy = async () => {
    // console.log("gameData.allCharacterList", gameData.allCharacterList);
    return await gameplayScenario.spawnEnemy();
  };

  if (!gameRef.current.leaderboard) gameRef.current.leaderboard = {};

  gameRef.current.leaderboard = async (top3, isWin) => {
    containerGameplay.visible = false;
    containerLobby.visible = false;
    lobbyScenario.reset();
    containerLeaderboard.visible = true;
    containerLeaderboardCharacter.visible = true;
    const filterCharacter = gameData?.allCharacterList?.filter((character) =>
      top3?.find((top) => character?.userID === top?.userID)
    );

    filterCharacter.forEach(async (item, index) => {
      // console.log("item", item);
      // item.setActive(true);
      const character = await loadCharacter(item.id, item.path);
      const rank1 = top3?.find((player) => player?.rank === 1);
      const rank2 = top3?.find((player) => player?.rank === 2);
      const rank3 = top3?.find((player) => player?.rank === 3);
      item = { ...item, ...character };
      item.setActive(true);

      // rank1.setActive(true);
      // rank2.setActive(true);
      // rank3.setActive(true);
      if (isWin) {
        item.playState("win");
      } else {
        item.playState("lose");
      }
      containerLeaderboardCharacter.addChild(item.container);
      const findUnuseContainer = item?.container?.children?.find(
        (child) => child?.id === item?.userID
      );
      item.container.removeChild(findUnuseContainer);
      if (item.userID === rank1?.userID) {
        item.container.position.set(-540, -408); // player rank 1 position
      }
      if (item.userID === rank2?.userID) {
        item.container.position.set(-770, -295); // player rank 2 position
      }
      if (item.userID === rank3?.userID) {
        item.container.position.set(-300, -230); // player rank 3 position
      }
    });
  };

  if (!gameRef.current.bossWalked) gameRef.current.bossWalkInStage = false;
  if (!gameRef.current.bossWalkInStage) gameRef.current.bossWalkInStage = {};

  gameRef.current.bossWalkInStage = async () => {
    let prevPos = containerGameplay.position.x;
    new Tweener(app.ticker)
      .insert(
        0.5,
        new Tween(1.5, (pos, dt) => {
          if (pos === 1) {
            prevPos = containerGameplay.position.x;
          } else {
            containerGameplay.position.x = pos * -1920 + prevPos;
          }
        })
      )
      .insert(
        2.5,
        new Tween(1, (pos, dt) => {
          containerGameplayCamera.scale.set(pos * 0.4 + 1, pos * 0.4 + 1);
        })
      )
      .insert(
        9,
        new Tween(1, (pos, dt) => {
          containerGameplayCamera.scale.set(pos * -0.4 + 1.4, pos * -0.4 + 1.4);
        })
      )
      .insert(
        10,
        new Tween(1.5, (pos, dt) => {
          containerGameplay.position.x = pos * 1920 + prevPos;
          if (pos === 1) {
            containerGameplay.scale.set(pos * 0 + 1, pos * 0 + 1);
          }
          gameRef.current.bossWalked = true;
        })
      )
      .play();
    setTimeout(async () => {
      await gameplayScenario.scenarioBossWalkInStage();
    }, 4000);
  };

  if (!gameRef.current.victory) gameRef.current.victory = {};

  gameRef.current.victory = async () => {
    await gameplayScenario.scenarioWin();
  };

  if (!gameRef.current.defeat) gameRef.current.defeat = {};
  gameRef.current.defeat = async () => {
    await gameplayScenario.scenarioLose();
  };

  if (!gameRef.current.isOneQuestionOnly)
    gameRef.current.isOneQuestionOnly = false;

  if (!gameRef.current.reset) gameRef.current.reset = {};

  gameRef.current.reset = async () => {
    containerLeaderboard.visible = false;
    containerGameplay.visible = false;
    containerLobby.visible = true;
    containerLeaderboardCharacter.removeChildren();
    containerGameplayCharacter.removeChildren();
    containerLobbyCharacter.removeChildren();
    gameplayScenario.reset();
    gameRef.current.bossWalked = false;
    gameRef.current.isReadyToPlay = false;
    gameRef.current.isOneQuestionOnly = false;
    gameData.allCharacterList.forEach((e) => {
      if (e.destroy) {
        e.destroy();
      }
    });
    gameData.lobbyCharacterList = [];
    gameData.allCharacterList = [];
  };

  gameRef.current.testLoadNewTheme = async (themeAssets) => {
    await loadLevel("nova-centauri", themeAssets).then((level) => {
      containerMockupBackground.addChild(level.parallax.container);
      // containerMockupBackground.position.set(-300, 0);
      containerMockupBackground.position.set(-3600, 0);
      // containerMockupBackground.position.set(-2500, 0);
      // containerMockupBackground.position.set(-2000, 0);
      // containerMockupBackground.position.set(-1300, 0);
    });
  };

  // const onTestTranslate = onTestTranslateScreen(app.ticker);
  // app.ticker.add(onTestTranslate);

  gameRef.current.preloadCharacter = async (characterDataList) => {
    let promises = [];

    characterDataList.forEach((character) => {
      promises.push(
        loadInitialCharacter(character.characterCode, character.spine)
      );
    });
    // console.log("promises", promises);
    return await Promise.all(promises).then(() => {
      console.log("app from gamecontent", app);
    });
  };
  return [app, onRelease];
};

export default GameContent;
