import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import JinParticle from "Game/PixiCores/Particles/Jin/JinParticle";
import { Tween, Tweener } from "Classes/Tween";
import Jin_Hydropump from "Game/Assets/ParticleSprite/Jin_Hydropump.png";
import * as Sentry from "@sentry/react";

let character = {
  controller: {},
  scale: 0.12,
  sequence: {
    idle: [
      { name: "Idle", loop: true },
      { name: "Idle", loop: true, delay: 0.4 },
    ],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charge", loop: false },
      { name: "Charging Loop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      // { name: "Idle", loop: false },
      { name: "Charge", loop: false },
      { name: "Attack", loop: false, delay: 0.2 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true, delay: 0 }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      // character.controller.setAttackTo(
      //     character.controller.targetPosition
      // )
    },
    LeftWaterSplash: () => {
      // character.controller.waterSplashLeft()
    },
    RightWaterSplash: () => {
      // character.controller.waterSplashRight()
    },
    WaterErupt: () => {
      // character.controller.waterErupt()
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++) {
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
    }
  },
  initial: async (app, path) => {
    return new Promise(async (resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_006", path);
      preload.load(async (loader, resources) => {
        // console.log("resources", resources);
        if (app.loader.resources) {
          app.loader.resources.character_VND_006 = resources;
        }
        // console.log("app", app);
      });
      preload.onComplete.add(async () => {
        await JinParticle.initial(app);
        resolve("load Jin complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          // const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_006) {
            preload.add("character_VND_006", path);
          }
          preload.load(async (loader, resources) => {
            let spineData =
              resources["character_VND_006"].character_VND_006.spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerWaterHit = new PIXI.Container();
              let containerWaterAtk = new PIXI.Container();
              let containerWater = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerWaterHit);
              container.addChild(containerWaterAtk);
              container.addChild(containerWater);

              await JinParticle.load(
                app,
                containerWaterHit,
                containerWater
              ).then((particle) => {
                emitterController = particle;
              });

              const controller = {
                spine,
                container,
                containerWaterHit,
                containerWaterAtk,
                containerWater,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "jin",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                charging: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  containerWaterHit.position.set(targetPosition.x, -80);
                  const delay = 0.65;
                  let bullets = [];
                  let Hydropump = PIXI.Texture.from(Jin_Hydropump);
                  const timetoEnemy = 0.75;

                  function shoot() {
                    var bullet = new PIXI.Sprite(Hydropump);
                    containerWaterAtk.addChild(bullet);
                    bullet.visible = false;
                    bullets.push(bullet);
                  }
                  for (let i = 0; i <= 9; i++) {
                    shoot();
                  }

                  function animate(props, pos) {
                    if (pos === 0) {
                      if (audioRef.current._group["VND-006"])
                        audioRef.current._group["VND-006"]._list[0].play(
                          "attack"
                        );
                    }
                    if (pos === 1) {
                      if (audioRef.current._group["VND-006"])
                        audioRef.current._group["VND-006"]._list[0].play("hit");
                    }
                    props.visible = true;
                    props.position.x = 25 + pos * targetPosition.x;
                    props.position.y = -170 + pos ** 4 * 50;
                    props.scale.set(1 + pos ** 4 * 2);
                    props.alpha = 1 + pos ** 4 * -1;
                  }

                  new Tweener(app.ticker)
                    .insert(
                      0.0,
                      new Tween(1, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-006"]) {
                            audioRef.current._group["VND-006"]._list[0].play(
                              "splash"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.JinErupt.container.visible = false;
                          emitterController.JinErupt.emitter.emit = false;
                          emitterController.JinEruptDot.container.visible = false;
                          emitterController.JinEruptDot.emitter.emit = false;
                        } else {
                          emitterController.JinErupt.container.position.y =
                            -100;
                          emitterController.JinErupt.container.visible = true;
                          emitterController.JinErupt.emitter.emit = true;
                          emitterController.JinErupt.emitter.update(dt);
                          emitterController.JinErupt.container.alpha =
                            1 + pos ** 4 * -1;

                          emitterController.JinEruptDot.container.position.y =
                            -100;
                          emitterController.JinEruptDot.container.visible = true;
                          emitterController.JinEruptDot.emitter.emit = true;
                          emitterController.JinEruptDot.emitter.update(dt);
                          emitterController.JinEruptDot.container.scale.set(
                            0 + pos ** 4 * 3
                          );
                          emitterController.JinEruptDot.container.alpha =
                            1 + pos ** 4 * -1;
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 1) {
                          if (audioRef.current._group["VND-006"])
                            audioRef.current._group["VND-006"]._list[0].play(
                              "hit"
                            );
                          emitterController.JinSplash.container.visible = false;
                          emitterController.JinSplash.emitter.emit = false;
                        } else {
                          emitterController.JinSplash.container.visible = true;
                          emitterController.JinSplash.emitter.emit = true;
                          emitterController.JinSplash.emitter.update(dt);
                          emitterController.JinSplash.container.scale.set(
                            0 + pos ** 4 * 3
                          );
                          emitterController.JinSplash.container.alpha =
                            1 + pos ** 4 * -1;
                        }
                      })
                    )
                    .insert(
                      0.0 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[0].visible = false;
                        } else {
                          animate(bullets[0], pos);
                        }
                      })
                    )
                    .insert(
                      0.1 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[1].visible = false;
                        } else {
                          animate(bullets[1], pos);
                        }
                      })
                    )
                    .insert(
                      0.2 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[2].visible = false;
                        } else {
                          animate(bullets[2], pos);
                        }
                      })
                    )
                    .insert(
                      0.3 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[3].visible = false;
                        } else {
                          animate(bullets[3], pos);
                        }
                      })
                    )
                    .insert(
                      0.4 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[4].visible = false;
                        } else {
                          animate(bullets[4], pos);
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[5].visible = false;
                        } else {
                          animate(bullets[5], pos);
                        }
                      })
                    )
                    .insert(
                      0.6 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[6].visible = false;
                        } else {
                          animate(bullets[6], pos);
                        }
                      })
                    )
                    .insert(
                      0.7 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[7].visible = false;
                        } else {
                          animate(bullets[7], pos);
                        }
                      })
                    )
                    .insert(
                      0.8 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          bullets[8].visible = false;
                        } else {
                          animate(bullets[8], pos);
                        }
                      })
                    )
                    .insert(
                      0.9 + delay,
                      new Tween(timetoEnemy, (pos, dt) => {
                        if (pos === 1) {
                          if (audioRef.current._group["VND-006"])
                            audioRef.current._group["VND-006"]._list[0].play(
                              "hitTarget"
                            );
                          bullets[9].visible = false;
                          containerWaterAtk.removeChildren();
                        } else {
                          animate(bullets[9], pos);
                        }
                      })
                    )
                    .insert(
                      2.25,
                      new Tween(1, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-006"]) {
                            audioRef.current._group["VND-006"]._list[0].play(
                              "splash"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.JinErupt.container.visible = false;
                          emitterController.JinErupt.emitter.emit = false;
                          emitterController.JinEruptDot.container.visible = false;
                          emitterController.JinEruptDot.emitter.emit = false;
                        } else {
                          emitterController.JinErupt.container.position.y =
                            -100;
                          emitterController.JinErupt.container.visible = true;
                          emitterController.JinErupt.emitter.emit = true;
                          emitterController.JinErupt.emitter.update(dt);
                          emitterController.JinErupt.container.alpha =
                            1 + pos ** 4 * -1;

                          emitterController.JinEruptDot.container.position.y =
                            -100;
                          emitterController.JinEruptDot.container.visible = true;
                          emitterController.JinEruptDot.emitter.emit = true;
                          emitterController.JinEruptDot.emitter.update(dt);
                          emitterController.JinEruptDot.container.scale.set(
                            0 + pos ** 4 * 3
                          );
                          emitterController.JinEruptDot.container.alpha =
                            1 + pos ** 4 * -1;
                        }
                      })
                    )
                    .play();

                  await character.controller.routineWait(1.5);
                },
                hurt: () => {
                  new Tweener(app.ticker)
                    .insert(
                      0.0,
                      new Tween(1, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-006"]) {
                            audioRef.current._group["VND-006"]._list[0].play(
                              "splash"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.JinLeft.container.visible = false;
                          emitterController.JinLeft.emitter.emit = false;
                        } else {
                          emitterController.JinLeft.container.visible = true;
                          emitterController.JinLeft.emitter.emit = true;
                          emitterController.JinLeft.emitter.update(dt);
                          emitterController.JinLeft.container.alpha =
                            1 + pos ** 4 * -1;
                          emitterController.JinLeft.container.position.x =
                            -60 + pos ** (1 / 2) * -20;
                          emitterController.JinLeft.container.position.y =
                            -80 + pos ** 2 * 10;
                          emitterController.JinLeft.container.angle =
                            -45 * pos ** (1 / 2);
                        }
                      })
                    )
                    .insert(
                      0.6,
                      new Tween(1, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-006"]) {
                            audioRef.current._group["VND-006"]._list[0].play(
                              "splash"
                            );
                          }
                        }
                        if (pos === 1) {
                          emitterController.JinRight.container.visible = false;
                          emitterController.JinRight.emitter.emit = false;
                        } else {
                          emitterController.JinRight.container.visible = true;
                          emitterController.JinRight.emitter.emit = true;
                          emitterController.JinRight.emitter.update(dt);
                          emitterController.JinRight.container.alpha =
                            1 + pos ** 4 * -1;
                          emitterController.JinRight.container.position.x =
                            60 + pos ** (1 / 2) * 20;
                          emitterController.JinRight.container.position.y =
                            -80 + pos ** 2 * 10;
                          emitterController.JinRight.container.angle =
                            45 * pos ** (1 / 2);
                        }
                      })
                    )
                    .play();
                },
                playState: (stateName) => {
                  if (character.sequence[stateName]) {
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_JIN" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
