import {
  // ParallaxBlock,
  // ParallaxLayer,
  ParallaxLoop,
} from "Classes/Parallax.js";
import * as PIXI from "pixi.js";
const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    return new Promise((resolve, reject) => {
      // console.log("assets", assets);

      const loader = new PIXI.Loader();
      // let spriteList = [
      //   { name: "sprites_multi_building" },
      //   { name: "sprites_multi_large" },
      //   { name: "sprites_multi_tree" },
      // ];
      // assets.forEach((element) => {
      //   // console.log("element", element);
      //   const path = element.link;
      //   const arr = path.split("/");
      //   const filename = arr[arr.length - 1].split(".");
      //   const type = filename[0];
      //   if (
      //     spriteList.find((sprite) => {
      //       return sprite.name === type;
      //     })
      //   ) {
      //     loader.add(type, element.link);
      //   }
      // });

      loader.add(
        "sprites_multi_building",
        "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Mayurabad/multi/sprites_multi_building.json"
      );
      loader.add(
        "sprites_multi_large",
        "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Mayurabad/multi/sprites_multi_large.json"
      );
      loader.add(
        "sprites_multi_tree",
        "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Mayurabad/multi/sprites_multi_tree.json"
      );

      loader.load((loader, resources) => {
        const spritesheetTextureBuilding =
          resources["sprites_multi_building"].spritesheet.textures;
        const spritesheetTextureLarge =
          resources["sprites_multi_large"].spritesheet.textures;
        const spritesheetTextureTree =
          resources["sprites_multi_tree"].spritesheet.textures;

        const parallax = new ParallaxLoop(new PIXI.Container());

        // console.log("spritesheetTextureBuilding", spritesheetTextureBuilding);
        //Mayurabad_0001_Layer2_Castle.png
        //Mayurabad_0002_Layer3_Ruin.png;
        //Mayurabad_0004_Layer5_MountainLeft.png;
        //Mayurabad_0005_Layer6_MountainRight.png;

        // console.log("spritesheetTextureLarge", spritesheetTextureLarge);
        //Mayurabad_0000_Layer1_Floor
        //Mayurabad_0006_Layer7_BG

        // console.log("spritesheetTextureTree", spritesheetTextureTree);
        //Mayurabad_0003_Layer4_Tree.png

        const BG = new PIXI.Sprite(
          spritesheetTextureLarge["Mayurabad_0006_Layer7_BG.png"]
        );
        BG.position.set(-720, -1020);

        const MountainRight = new PIXI.Sprite(
          spritesheetTextureBuilding["Mayurabad_0005_Layer6_MountainRight.png"]
        );
        MountainRight.position.set(2200, -920);
        MountainRight.scale.set(1.15);

        const MountainLeft = new PIXI.Sprite(
          spritesheetTextureBuilding["Mayurabad_0004_Layer5_MountainLeft.png"]
        );
        MountainLeft.position.set(-620, -1000);
        MountainLeft.scale.set(1.15);

        const Tree = new PIXI.Sprite(
          spritesheetTextureTree["Mayurabad_0003_Layer4_Tree.png"]
        );
        Tree.position.set(300, -1010);
        Tree.scale.set(1.15);

        const Ruin = new PIXI.Sprite(
          spritesheetTextureBuilding["Mayurabad_0002_Layer3_Ruin.png"]
        );
        Ruin.position.set(2350, -1010);
        Ruin.scale.set(1.15);

        const Castle = new PIXI.Sprite(
          spritesheetTextureBuilding["Mayurabad_0001_Layer2_Castle.png"]
        );

        Castle.position.set(-960, -1000);
        Castle.scale.set(1.15);

        const Floor = new PIXI.Sprite(
          spritesheetTextureLarge["Mayurabad_0000_Layer1_Floor.png"]
        );
        Floor.position.set(-720, -160);
        // Floor.scale.set(1.25);

        parallax.container.addChild(BG);
        parallax.container.addChild(MountainRight);
        parallax.container.addChild(MountainLeft);
        parallax.container.addChild(Tree);
        parallax.container.addChild(Ruin);
        parallax.container.addChild(Castle);
        parallax.container.addChild(Floor);

        // parallax.addLayer(layerClouds);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            parallax.destroy();
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
