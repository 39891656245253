import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import CelesteParticle from "Game/PixiCores/Particles/Celeste/CelesteParticle";
import { Tween, Tweener } from "Classes/Tween";
import trail from "Game/Assets/trail.png";
import utils from "Service/UtilsCalculate";
import * as Sentry from "@sentry/react";

let character = {
  controller: {},
  scale: 0.14,
  sequence: {
    idle: [
      { name: "Idle", loop: true },
      { name: "Idle", loop: true, delay: 0.25 },
    ],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charge", loop: false },
      { name: "Charging Loop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Idle", loop: true },
      { name: "Charge", loop: false, delay: 0.6 },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Idle", loop: true },
      { name: "Hit", loop: false, delay: 0.15 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      // character.controller.setAttackTo(
      //     character.controller.targetPosition
      // )
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  initial: async (app, path) => {
    return new Promise((resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_004", path);
      preload.load(async (loader, resources) => {
        // console.log("resources", resources);
        if (app.loader.resources) {
          app.loader.resources.character_VND_004 = resources;
        }
        // console.log("app", app);
      });
      preload.onComplete.add(async () => {
        await CelesteParticle.initial(app);
        resolve("load Celeste complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          // const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_004) {
            preload.add("character_VND_004", path);
          }
          preload.load(async (loader, resources) => {
            let spineData =
              resources["character_VND_004"].character_VND_004.spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let containerCloud = new PIXI.Container();
              let containerGround = new PIXI.Container();
              let containerBullet = new PIXI.Container();
              let containerTrail = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(containerCloud);
              container.addChild(containerGround);
              container.addChild(containerBullet);
              container.addChild(containerTrail);

              await CelesteParticle.load(
                app,
                containerCloud,
                containerGround
              ).then((particle) => {
                emitterController = particle;
              });

              const controller = {
                spine,
                container,
                containerParticle,
                containerCloud,
                containerGround,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "celeste",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                charging: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  const delay = 0.65;
                  const trailTexture = PIXI.Texture.from(trail);

                  const historyX = [];
                  const historyY = [];
                  const historySize = 1500;
                  const ropeSize = 500;
                  const points = [];

                  containerCloud.position.x = targetPosition.x;
                  containerGround.position.x = targetPosition.x;

                  containerTrail.position.set(0, 0);
                  for (let i = 0; i < historySize; i++) {
                    historyX.push(containerBullet.position.x);
                    historyY.push(containerBullet.position.y);
                  }

                  for (let i = 0; i < ropeSize; i++) {
                    points.push(new PIXI.Point(0, 0));
                  }

                  const rope = new PIXI.SimpleRope(trailTexture, points, 0);
                  rope.tint = 0x00faff;
                  rope.blendmode = PIXI.BLEND_MODES.ADD;
                  containerTrail.position.set(-50, -180);
                  containerTrail.addChild(rope);

                  new Tweener(app.ticker)
                    .insert(
                      0.0 + delay,
                      new Tween(0.2, (pos, dt) => {
                        if (pos === 0) {
                          audioRef.current._group["VND-004"]._list[0].play(
                            "attack"
                          );
                        }
                        if (pos === 1) {
                          audioRef.current._group["VND-004"]._list[0].play(
                            "hitTarget"
                          );
                          // containerBullet.visible = false;
                          containerBullet.position.set(0, 0);
                          containerTrail.position.set(0, 0);
                          containerTrail.removeChildren();
                        } else {
                          containerBullet.position.x =
                            pos ** (Math.random() * 2 + 1) *
                            (targetPosition.x + 45);
                          containerBullet.position.y =
                            pos ** (Math.random() * 2 + 1) * 160;
                          // if (!containerBullet.visible)
                          //     containerBullet.visible = true;
                          historyX.pop();
                          historyX.unshift(containerBullet.position.x);
                          historyY.pop();
                          historyY.unshift(containerBullet.position.y);
                          for (let i = 0; i < ropeSize; i++) {
                            const p = points[i];
                            const ix = utils.cubicInterpolation(
                              historyX,
                              (i / ropeSize) * historySize
                            );
                            const iy = utils.cubicInterpolation(
                              historyY,
                              (i / ropeSize) * historySize
                            );
                            p.x = ix;
                            p.y = iy;
                          }
                        }
                      })
                    )
                    .insert(
                      0.15 + delay,
                      new Tween(0.2, (pos, dt) => {
                        if (pos === 1) {
                          containerGround.visible = false;
                          emitterController.CelesteGroundHit.container.visible = false;
                          emitterController.CelesteGroundHit.emitter.emit = false;
                          emitterController.CelesteGroundHit.emitter.cleanup();
                        } else {
                          containerGround.visible = true;
                          emitterController.CelesteGroundHit.container.visible = true;
                          emitterController.CelesteGroundHit.emitter.emit = true;
                          emitterController.CelesteGroundHit.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.1 + delay,
                      new Tween(2.9, (pos, dt) => {
                        if (pos === 1) {
                          containerCloud.visible = false;
                          emitterController.CelesteCloudBack.container.visible = false;
                          emitterController.CelesteCloudBack.emitter.emit = false;
                          emitterController.CelesteCloudBack.emitter.cleanup();

                          emitterController.CelesteCloudFront.container.visible = false;
                          emitterController.CelesteCloudFront.emitter.emit = false;
                          emitterController.CelesteCloudFront.emitter.cleanup();
                        } else {
                          containerCloud.visible = true;

                          emitterController.CelesteCloudBack.container.visible = true;
                          emitterController.CelesteCloudBack.emitter.emit = true;
                          emitterController.CelesteCloudBack.emitter.update(dt);
                          emitterController.CelesteCloudBack.container.scale.set(
                            pos ** (1 / 4)
                          );
                          emitterController.CelesteCloudBack.container.position.y =
                            -20 + pos ** (1 / 4) * -170;

                          emitterController.CelesteCloudFront.container.visible = true;
                          emitterController.CelesteCloudFront.emitter.emit = true;
                          emitterController.CelesteCloudFront.emitter.update(
                            dt
                          );
                          emitterController.CelesteCloudFront.container.scale.set(
                            pos ** (1 / 4)
                          );
                          emitterController.CelesteCloudFront.container.position.y =
                            20 + pos ** (1 / 4) * -170;
                        }
                      })
                    )
                    .play();
                  await character.controller.routineWait(0.8);
                },
                hurt: () => {},
                playState: (stateName) => {
                  if (character.sequence[stateName]) {
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                    if (stateName === "attack") {
                      if (audioRef.current._group["VND-004"])
                        audioRef.current._group["VND-004"]._list[0].play(
                          "charge"
                        );
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                  controller.containerCloud?.destroy();
                  controller.containerGround?.destroy();
                  controller.containerBullet?.destroy();
                  controller.containerTrail?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_CELESTE" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
