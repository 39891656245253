import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import AstronautParticle from "Game/PixiCores/Particles/Astronaut/AstronautParticle.js";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

let character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charge", loop: false },
      { name: "ChargingLoop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Idle", loop: true },
      { name: "Charge", loop: false, delay: 0 },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {},
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  initial: async (app, path) => {
    return new Promise((resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_015", path);
      preload.load(async (loader, resources) => {
        if (app.loader.resources) {
          app.loader.resources.character_VND_015 = resources;
        }
      });
      preload.onComplete.add(async () => {
        // await AstronautParticle.initial(app);
        resolve("load Marcus complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          //   const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_015) {
            preload.add("character_VND_015", path);
          }
          preload.load(async (loader, resources) => {
            let spineData =
              resources["character_VND_015"].character_VND_015.spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.skeleton.setSkinByName("Marcus");
              let container = new PIXI.Container();
              let containerBullet = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerBullet);

              await AstronautParticle.load(app, containerBullet, "Marcus").then(
                (particle) => {
                  emitterController = particle;
                }
              );

              const controller = {
                spine,
                container,
                infoCharacter: {
                  characterName: "Marcus",
                },
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                charging: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  new Tweener(app.ticker)
                    .insert(
                      0.76,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["VND-015"]) {
                            audioRef.current._group["VND-015"]._list[0].play(
                              "fired"
                            );
                            audioRef.current._group["VND-015"]._list[0].play(
                              "release"
                            );
                          }
                          emitterController.BulletContainer.visible = true;
                        }
                        if (pos === 1) {
                          if (audioRef.current._group["VND-015"]) {
                            audioRef.current._group["VND-015"]._list[0].play(
                              "hitTarget"
                            );
                          }
                          emitterController.BulletContainer.visible = false;
                        } else {
                          emitterController.BulletContainer.position.set(
                            120 + pos * (targetPosition.x - 120),
                            -140 + pos * 50
                          );
                        }
                      })
                    )

                    .play();

                  if (audioRef.current._group["VND-015"]) {
                    audioRef.current._group["VND-015"]._list[0].play("stretch");
                    setTimeout(() => {
                      audioRef.current._group["VND-015"]._list[0].play(
                        "calculate"
                      );
                    }, 1333);
                  }

                  await character.controller.routineWait(1.25);

                  // console.log(emitterController)
                },

                hurt: () => {
                  if (audioRef.current._group["VND-015"]) {
                    audioRef.current._group["VND-015"]._list[0].play("danger");

                    audioRef.current._group["VND-015"]._list[0].play(
                      "helmetPop"
                    );
                    setTimeout(() => {
                      audioRef.current._group["VND-015"]._list[0].play(
                        "connectHelmet"
                      );
                    }, 1500);
                  }
                },
                playState: (stateName) => {
                  if (character.sequence[stateName]) {
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  }
                  if (stateName === "charge") {
                    if (audioRef.current._group["VND-015"]) {
                      audioRef.current._group["VND-015"]._list[0].play(
                        "calculate"
                      );
                    }
                  }
                  if (stateName === "win") {
                    if (audioRef.current._group["VND-015"]) {
                    }
                  }
                  if (stateName === "lose") {
                    if (audioRef.current._group["VND-015"]) {
                      audioRef.current._group["VND-015"]._list[0].play(
                        "puffUp"
                      );
                      setTimeout(() => {
                        audioRef.current._group["VND-015"]._list[0].play(
                          "takeOffCloth"
                        );
                      }, 500);
                      setTimeout(() => {
                        audioRef.current._group["VND-015"]._list[0].play(
                          "explode"
                        );
                      }, 1500);
                    }
                  }
                },
                destroy: () => {
                  controller.spine?.destroy();
                  controller.container?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_Marcus" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
