import {
  // ParallaxBlock,
  // ParallaxLayer,
  ParallaxLoop,
} from "Classes/Parallax.js";
import * as PIXI from "pixi.js";

import * as Particles from "pixi-particles";
import sakura_01 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0000_Layer-3.png";
import sakura_02 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0001_Layer-4.png";
import sakura_03 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0002_Layer-5.png";
import sakura_04 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0003_Layer-6.png";
import sakura_05 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0004_Layer-7.png";
import sakura_06 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0005_Layer-8.png";
import sakura_07 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0006_Layer-9.png";
import sakura_08 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0007_Layer-10.png";
import sakura_09 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0008_Layer-11.png";
import sakura_10 from "Game/Assets/LevelParticleSprite/Tsuronotani/Sakura-petal_0009_Layer-2.png";
import sakura from "Game/PixiCores/Levels/sakura.json";

const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    // console.log(assets);
    return new Promise((resolve, reject) => {
      const loader = new PIXI.Loader();
      let spriteList = [
        { name: "sprites_multi_building" },
        { name: "sprites_multi_large_0" },
        { name: "sprites_multi_large_1" },
        { name: "sprites_multi_environments_0" },
        { name: "sprites_multi_environments_1" },
      ];
      assets.forEach((element) => {
        // console.log("element", element);
        const path = element.link;
        const arr = path.split("/");
        const filename = arr[arr.length - 1].split(".");
        const type = filename[0];
        if (
          spriteList.find((sprite) => {
            return sprite.name === type;
          })
        ) {
          loader.add(type, element.link);
        }
      });

      let particleList = [
        sakura_01,
        sakura_02,
        sakura_03,
        sakura_04,
        sakura_05,
        sakura_06,
        sakura_07,
        sakura_08,
        sakura_09,
        sakura_10,
      ];

      particleList.forEach((element, index) => {
        loader.add(index.toString(), element);
      });

      loader.load((loader, resources) => {
        // console.log(resources)
        const spritesheetTextureLarge0 =
          resources["sprites_multi_large_0"].spritesheet.textures;
        const spritesheetTextureLarge1 =
          resources["sprites_multi_large_1"].spritesheet.textures;
        const spritesheetTextureBuilding =
          resources["sprites_multi_building"].spritesheet.textures;
        const spritesheetTextureEnv1 =
          resources["sprites_multi_environments_1"].spritesheet.textures;
        const spritesheetTextureEnv0 =
          resources["sprites_multi_environments_0"].spritesheet.textures;

        const parallax = new ParallaxLoop(new PIXI.Container());

        const BG = new PIXI.Sprite(
          spritesheetTextureLarge0["Tsuru_no_Tani_0010_Layer_11_BG.png"]
        );
        BG.position.set(-960, -1010);

        const Cloud = new PIXI.Sprite(
          spritesheetTextureLarge1["Tsuru_no_Tani_0009_Layer_10_Cloud.png"]
        );
        Cloud.position.set(840, -1020);

        const Fuji = new PIXI.Sprite(
          spritesheetTextureLarge1["Tsuru_no_Tani_0008_Layer9_Fuji.png"]
        );
        Fuji.position.set(-100, -860);

        const Forest = new PIXI.Sprite(
          spritesheetTextureEnv1["Tsuru_no_Tani_0006_Layer7_EnemyForest.png"]
        );
        Forest.position.set(1000, -1010);
        Forest.scale.set(1.15);

        const EnemyHill = new PIXI.Sprite(
          spritesheetTextureEnv1["Tsuru_no_Tani_0005_Layer6_EnemyHill.png"]
        );
        EnemyHill.position.set(2040, -1010);
        EnemyHill.scale.set(1.15);

        const Arch = new PIXI.Sprite(
          spritesheetTextureBuilding["Tsuru_no_Tani_0004_Layer5_Arch.png"]
        );
        Arch.position.set(2260, -990);
        Arch.scale.set(1.15);

        const Buddha = new PIXI.Sprite(
          spritesheetTextureLarge1["Tsuru_no_Tani_0007_Layer8_Buddha.png"]
        );
        Buddha.position.set(-920, -1010);
        Buddha.scale.set(1.1);

        const Hill = new PIXI.Sprite(
          spritesheetTextureEnv0["Tsuru_no_Tani_0003_Layer4_Hill.png"]
        );
        Hill.position.set(-960, -1020);
        Hill.scale.set(1.125);

        const Sakura = new PIXI.Sprite(
          spritesheetTextureEnv0["Tsuru_no_Tani_0002_Layer3_Sakura.png"]
        );
        Sakura.position.set(-1000, -1040);
        Sakura.scale.set(1.15);

        const Castle = new PIXI.Sprite(
          spritesheetTextureBuilding["Tsuru_no_Tani_0001_Layer2_Castle.png"]
        );
        Castle.position.set(-840, -950);
        Castle.scale.set(1.15);

        const Floor = new PIXI.Sprite(
          spritesheetTextureLarge0["Tsuru_no_Tani_0000_Layer1_Floor.png"]
        );
        Floor.position.set(-960, -115);
        Floor.scale.set(1.05);

        parallax.container.addChild(BG);

        parallax.container.addChild(Cloud);
        parallax.container.addChild(Fuji);
        parallax.container.addChild(Buddha);

        parallax.container.addChild(Forest);
        parallax.container.addChild(EnemyHill);
        parallax.container.addChild(Arch);

        parallax.container.addChild(Hill);
        parallax.container.addChild(Sakura);
        parallax.container.addChild(Castle);
        parallax.container.addChild(Floor);

        const textures = [];
        for (let index = 0; index < 8; index++) {
          textures.push(resources[index].texture);
        }
        const particleContainer = new PIXI.Container();
        const emitter = new Particles.Emitter(
          particleContainer,
          textures,
          sakura.data
        );
        emitter.emit = true;
        let ticker = app.ticker;
        let deltaTime = (1 / ticker.FPS) * ticker.speed;
        let updateSakura = () => {
          emitter.update(deltaTime);
        };
        app.ticker.add(updateSakura);

        parallax.container.addChild(particleContainer);
        particleContainer.position.set(-1000, -1500);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            parallax.destroy();
            app.ticker.remove(updateSakura);
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
