import * as PIXI from "pixi.js";

import chrisIXBullet from "Game/PixiCores/Particles/Astronaut/Bullet_small.png";
import heinrichBullet from "Game/PixiCores/Particles/Astronaut/Bullet_Heinrich.png";
import pierreBullet from "Game/PixiCores/Particles/Astronaut/Bullet_Pierre.png";

const AstronautParticle = {
  initial: async (app) => {},

  load: (app, containerBullet, name) => {
    return new Promise(async (resolve, reject) => {
      let path = "";
      switch (name) {
        case "Heinrich":
          path = heinrichBullet;
          break;
        case "Pierre":
          path = pierreBullet;
          break;
        default:
          path = chrisIXBullet;
          break;
      }
      // const BulletContainer = new PIXI.Sprite(PIXI.Texture.from(chrisIXBullet));
      const BulletContainer = new PIXI.Sprite(PIXI.Texture.from(path));
      BulletContainer.anchor.set(0.5);
      BulletContainer.visible = false;
      containerBullet.addChild(BulletContainer);
      // console.log("load Astronaut particle");
      resolve({ BulletContainer });

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default AstronautParticle;
