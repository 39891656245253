import {
  TimeCard,
  TextTypo,
  TimeCardProgress,
  Box,
} from "Game/Style/GameStyle";
import { memo, useEffect, useMemo, useState } from "react";
import { useVonderGoContext } from "Service/VonderGOContext";
import { useLocalizationContext } from "Service/LocalizationProvider";
import styled from "styled-components";
import { ReactComponent as TimeIcon } from "Game/Assets/wall-clock.svg";

export default memo(function TimeBox() {
  const [time, setTime] = useState(null);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [isAllUserAnswered, setIsAllUserAnswered] = useState(false);
  const {
    wsSend,
    totalUsers,
    gameContextState,
    currentGameData,
    animationState,
    dispatchAnimationState,
    currentPhase,
    setShowMediaModal,
  } = useVonderGoContext();

  const { t } = useLocalizationContext();

  //#region filter current user answer
  const currentUserAnswer = useMemo(() => {
    return gameContextState?.userAnswer?.filter(
      (item) => item?.questionIndex === currentGameData?.questionIndex
    );
  }, [gameContextState, currentGameData]);
  //#endregion

  //#region set answering time
  useEffect(() => {
    if (currentGameData) {
      setTime(currentGameData?.time);
      setIsAllUserAnswered(false);
    }
  }, [currentGameData]);
  //#endregion

  useEffect(() => {
    if (currentUserAnswer?.length === totalUsers?.length || isTimeOut) {
      setIsTimeOut(true);
    } else {
      setIsTimeOut(false);
    }
  }, [currentUserAnswer?.length, isTimeOut, totalUsers?.length]);

  // #region countdown answering time
  // useEffect(() => {
  //   const countTime =
  //     time > 0 &&
  //     currentUserAnswer?.length !== totalUsers?.length &&
  //     setInterval(() => setTime((prev) => prev - 1), 1000);
  //   return () => clearInterval(countTime);
  // }, [currentUserAnswer, time, totalUsers]);
  useEffect(() => {
    const countTime =
      time > 0 &&
      !isAllUserAnswered &&
      setInterval(() => setTime((prev) => prev - 1), 1000);
    return () => clearInterval(countTime);
  }, [isAllUserAnswered, time]);
  // #endregion

  //#region check times'up and all user answer
  useEffect(() => {
    if (isTimeOut) {
      setIsAllUserAnswered(true);
      if (currentPhase !== "explanation") {
        setShowMediaModal(false);
        dispatchAnimationState({ type: "close_side_card", payload: true });
        dispatchAnimationState({
          type: "close_question_to_answering",
          payload: false,
        });
        dispatchAnimationState({ type: "button_visible", payload: false });
        console.log("explanation-ready from use effect => ", currentPhase);
        wsSend(JSON.stringify({ event: "explanation-ready" }));
      }
    }
  }, [currentUserAnswer, dispatchAnimationState, isTimeOut, totalUsers, currentPhase, setShowMediaModal, wsSend]);
  //#endregion

  return (
    <TimeCard
      align="center"
      justify="center"
      close={animationState?.closeSideCard}
    >
      <Box
        direction="column"
        align="center"
        justify="center"
        width="100%"
        height="100%"
      >
        <IconStyle />
        <TextTypo size="1vw" strong="true" type="secondary">
          {t("GAMEPLAY.TIME")}
        </TextTypo>
        <TextTypo size="3.5vw" strong="true" type="primary">
          {time}
        </TextTypo>
      </Box>
      <TimeCardProgress progress={time} time={currentGameData?.time} />
    </TimeCard>
  );
});

const IconStyle = styled(TimeIcon)`
  width: 50px;
  path {
    fill: var(--blue-500);
  }
`;
