import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import KosmoParticle from "Game/PixiCores/Particles/Kosmo/KosmoParticle.js";

import { Tween, Tweener } from "Classes/Tween";

const character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      //   character.controller.setAttackTo(character.controller.targetPosition);
    },
    AttackFinal: () => {
      //   character.controller.setFinalAttackTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: async (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            // console.log(spineData);
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let bulletParticle = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(bulletParticle);

              containerParticle.addChild(bulletParticle);

              await KosmoParticle.load({
                app,
                bulletParticle,
                containerParticle,
              }).then((particle) => {
                emitterController = particle;
                // console.log("emitterController", emitterController);
              });

              const controller = {
                spine,
                container,
                containerParticle,
                bulletParticle,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "Kosmo",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                setAttackTo: (targetPosition) => {},
                setFinalAttackTo: (targetPosition) => {},
                attackPlayer: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };

                    let bulletController =
                      emitterController.allBullet[index].controller;

                    // KosmoParticle
                    // console.log("bulletController", bulletController);
                    // console.log("targetPosition x", targetPosition.x);

                    // bulletController.smallLaserContainer.visible = true;
                    // bulletController.smallLaserContainer.angle =
                    //   -15 + index * 2;
                    // bulletController.smallLaserContainer.scale.x =
                    //   index * 0.3 - 0.1;
                    bulletController.biteAnimate.position.set(
                      targetPosition.x,
                      targetPosition.y + 40
                    );
                    new Tweener(app.ticker)
                      .insert(
                        0.5,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 1) {
                            if (audioRef.current._group["BOS-007"]) {
                              audioRef.current._group["BOS-007"]._list[0].play(
                                "attack"
                              );
                            }
                            bulletController.biteAnimate.visible = false;
                            bulletController.biteAnimate.stop();
                          } else if (pos === 0) {
                            bulletController.biteAnimate.visible = true;
                            bulletController.biteAnimate.gotoAndPlay(0);
                          } else {
                          }
                        })
                      )
                      .play();
                  });

                  await character.controller.routineWait(1);
                },
                finalAttack: async (self, allTargetPlayer) => {
                  const containerLight = new PIXI.Container();
                  const lightingFX = new PIXI.Sprite(PIXI.Texture.WHITE);
                  lightingFX.tint = 0x000000;
                  // lightingFX.blendMode = PIXI.BLEND_MODES.ADD;

                  lightingFX.anchor.set(0.5);
                  lightingFX.width = 4000;
                  lightingFX.height = 2000;

                  containerLight.addChild(lightingFX);
                  containerParticle.addChild(containerLight);

                  // console.log("final attack from boss");

                  new Tweener(app.ticker)
                    .insert(
                      0,
                      new Tween(2.5, (pos, dt) => {
                        if (pos === 1) {
                        }
                        if (pos === 0) {
                          containerLight.visible = true;
                        } else {
                          lightingFX.alpha = pos * 0.8;
                          // emitterController.allCurseWord.forEach(
                          //   (curseContainer) => {
                          //     curseContainer.alpha = pos;
                          //   }
                          // );
                        }
                      })
                    )
                    .insert(
                      2.5,
                      new Tween(2.5, (pos, dt) => {
                        if (pos === 1) {
                          containerLight.visible = false;
                        }
                        if (pos === 0) {
                        } else {
                          lightingFX.alpha = 0.8 - pos * 0.8;
                          // emitterController.allCurseWord.forEach(
                          //   (curseContainer) => {
                          //     curseContainer.alpha = 1 - pos;
                          //   }
                          // );
                        }
                      })
                    )
                    .play();

                  emitterController.allCurseWord.forEach((curseContainer) => {
                    // curseContainer.visible = true;
                    curseContainer.alpha = 0;
                    // curseContainer.angle = -5;
                    // curseContainer.curseVelocity = 0.1 + Math.random() * 2;
                    const rngX = 300 + Math.random() * -2000;
                    const rngY = 100 + Math.random() * -1800;
                    curseContainer.position.set(rngX, rngY);
                  });

                  const curseTween = new Tweener(app.ticker);
                  emitterController.allCurseWord.forEach(
                    (curseContainer, index) => {
                      const rngMaxScale = 0.1 + Math.random() * 0.3;

                      curseTween.insert(
                        0.5 + 0.005 * index,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 0) {
                            curseContainer.visible = true;
                          } else {
                            curseContainer.alpha = pos;
                            curseContainer.scale.set(pos * rngMaxScale);
                          }
                        })
                      );
                      curseTween.insert(
                        0.5 + 0.005 * index + 0.5,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 1) {
                            curseContainer.visible = false;
                          } else {
                            curseContainer.alpha = 1 - pos;
                            curseContainer.scale.set(
                              rngMaxScale - pos * rngMaxScale
                            );
                          }
                        })
                      );
                    }
                  );
                  curseTween.play();

                  if (audioRef.current._group["BOS-007"]) {
                    audioRef.current._group["BOS-007"]._list[0].play(
                      "finalAttack"
                    );
                  }
                  await character.controller.routineWait(1.5);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["BOS-007"]) {
                      audioRef.current._group["BOS-007"]._list[0].play("dead");
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-007"])
                    audioRef.current._group["BOS-007"]._list[0].play("talk");
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_DMITRI" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default character;
