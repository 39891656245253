import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import DmitriParticle from "Game/PixiCores/Particles/Dmitri/DmitriParticle.js";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.15,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Walk", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      //   character.controller.setAttackTo(character.controller.targetPosition);
    },
    AttackFinal: () => {
      //   character.controller.setFinalAttackTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: async (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            // console.log(spineData);
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let stompParticle = new PIXI.Container();
              let spikeParticle = new PIXI.Container();
              let containerBulletGenerate = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(stompParticle);
              container.addChild(spikeParticle);
              container.addChild(containerBulletGenerate);

              containerParticle.addChild(stompParticle);
              containerParticle.addChild(spikeParticle);
              containerParticle.addChild(containerBulletGenerate);

              await DmitriParticle.load(
                app,
                stompParticle,
                spikeParticle,
                containerBulletGenerate
              ).then((particle) => {
                emitterController = particle;
              });

              const controller = {
                spine,
                container,
                containerParticle,
                stompParticle,
                spikeParticle,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "dmitri",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                setAttackTo: (targetPosition) => {
                  containerParticle.position.set(targetPosition.x, 0);
                  // console.log(emitterController.Arrow.container);

                  new Tweener(app.ticker)
                    .insert(
                      0.0,
                      new Tween(0.3, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Arrow.container.visible = false;
                          emitterController.Arrow.emitter.emit = false;
                        } else {
                          emitterController.Arrow.container.visible = true;
                          emitterController.Arrow.emitter.emit = true;
                          emitterController.Arrow.emitter.update(dt);
                          containerParticle.position.x =
                            -10 + targetPosition.x * pos;
                          containerParticle.position.y = -150 + pos ** 4 * 80;
                          emitterController.Arrow.container.angle =
                            -45 * pos ** (1 / 2);
                        }
                      })
                    )
                    .play();
                },
                setFinalAttackTo: (targetPosition) => {
                  stompParticle.position.set(0, -20);
                  spikeParticle.position.set(
                    targetPosition.x,
                    targetPosition.y
                  );

                  emitterController.IceSpike1.container.position.x = -50;
                  emitterController.IceSpike2.container.position.x = 0;
                  emitterController.IceSpike3.container.position.x = 60;

                  new Tweener(app.ticker)
                    .insert(
                      0.0,
                      new Tween(2.75, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.StompImpact.container.visible = false;
                          emitterController.StompImpact.emitter.emit = false;

                          emitterController.StompImpactDust.container.visible = false;
                          emitterController.StompImpactDust.emitter.emit = false;

                          emitterController.IceSpike1.container.visible = false;
                          emitterController.IceSpike1.emitter.emit = false;

                          emitterController.IceSpike2.container.visible = false;
                          emitterController.IceSpike2.emitter.emit = false;

                          emitterController.IceSpike3.container.visible = false;
                          emitterController.IceSpike3.emitter.emit = false;

                          emitterController.SpikeImpactDust.container.visible = false;
                          emitterController.SpikeImpactDust.emitter.emit = false;
                        } else {
                          emitterController.StompImpact.container.visible = true;
                          emitterController.StompImpact.emitter.emit = true;
                          emitterController.StompImpact.emitter.update(dt);
                          emitterController.StompImpactDust.container.scale.set(
                            1 + pos ** 2 * 0.5
                          );
                          emitterController.StompImpactDust.container.position.y =
                            0 + pos ** 2 * -10;
                          emitterController.StompImpact.container.alpha =
                            1 + pos ** 2 * -1;

                          emitterController.StompImpactDust.container.visible = true;
                          emitterController.StompImpactDust.emitter.emit = true;
                          emitterController.StompImpactDust.emitter.update(dt);
                          emitterController.StompImpactDust.container.scale.set(
                            1 + pos ** 2 * 0.5
                          );
                          emitterController.StompImpactDust.container.position.y =
                            0 + pos ** 2 * -10;
                          emitterController.StompImpactDust.container.alpha =
                            1 + pos ** 4 * -1;

                          emitterController.SpikeImpactDust.container.visible = true;
                          emitterController.SpikeImpactDust.emitter.emit = true;
                          emitterController.SpikeImpactDust.emitter.update(dt);
                          emitterController.SpikeImpactDust.container.scale.set(
                            1 + pos ** 2 * 1
                          );
                          emitterController.SpikeImpactDust.container.position.y =
                            -5 + pos ** 2 * -20;
                          emitterController.SpikeImpactDust.container.alpha =
                            1 + pos ** 4 * -1;

                          emitterController.IceSpike1.container.alpha =
                            1 + pos ** 8 * -1;
                          emitterController.IceSpike2.container.alpha =
                            1 + pos ** 8 * -1;
                          emitterController.IceSpike3.container.alpha =
                            1 + pos ** 8 * -1;
                        }
                      })
                    )
                    .insert(
                      0.1,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.StompImpactParticle.container.visible = false;
                          emitterController.StompImpactParticle.emitter.emit = false;

                          emitterController.SpikeImpactParticle.container.visible = false;
                          emitterController.SpikeImpactParticle.emitter.emit = false;
                        } else {
                          emitterController.StompImpactParticle.container.visible = true;
                          emitterController.StompImpactParticle.emitter.emit = true;
                          emitterController.StompImpactParticle.emitter.update(
                            dt
                          );
                          emitterController.StompImpactParticle.container.scale.set(
                            1 + pos ** 2 * 5
                          );
                          emitterController.StompImpactParticle.container.position.y =
                            0 + pos ** 2 * -25;
                          emitterController.StompImpactParticle.container.alpha =
                            1 + pos ** 2 * -1;

                          emitterController.SpikeImpactParticle.container.visible = true;
                          emitterController.SpikeImpactParticle.emitter.emit = true;
                          emitterController.SpikeImpactParticle.emitter.update(
                            dt
                          );
                          emitterController.SpikeImpactParticle.container.scale.set(
                            1 + pos ** 2 * 5
                          );
                          emitterController.SpikeImpactParticle.container.position.y =
                            0 + pos ** 2 * -25;
                          emitterController.SpikeImpactParticle.container.alpha =
                            1 + pos ** 2 * -1;
                        }
                      })
                    )
                    .insert(
                      0.0,
                      new Tween(0.25, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.IceSpike3.container.visible = true;
                          emitterController.IceSpike3.emitter.emit = true;
                          emitterController.IceSpike3.emitter.update(dt);
                          emitterController.IceSpike3.container.scale.set(
                            0 + pos ** 4 * 1
                          );
                          emitterController.IceSpike3.container.position.y =
                            0 + pos ** 4 * -25;
                        }
                      })
                    )
                    .insert(
                      0.075,
                      new Tween(0.25, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.IceSpike2.container.visible = true;
                          emitterController.IceSpike2.emitter.emit = true;
                          emitterController.IceSpike2.emitter.update(dt);
                          emitterController.IceSpike2.container.scale.set(
                            0 + pos ** 4 * 1
                          );
                          emitterController.IceSpike2.container.position.y =
                            -5 + pos ** 4 * -25;
                        }
                      })
                    )
                    .insert(
                      0.15,
                      new Tween(0.25, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.IceSpike1.container.visible = true;
                          emitterController.IceSpike1.emitter.emit = true;
                          emitterController.IceSpike1.emitter.update(dt);
                          emitterController.IceSpike1.container.scale.set(
                            0 + pos ** 4 * 1
                          );
                          emitterController.IceSpike1.container.position.y =
                            -5 + pos ** 4 * -25;
                        }
                      })
                    )
                    .play();
                },
                attackPlayer: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    // console.log("bulletController", bulletController);
                    bulletController.containerArrow.visible = true;
                    bulletController.containerArrow.position.set(0, 0);
                    new Tweener(app.ticker)
                      .insert(
                        1.0,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 0) {
                            if (audioRef.current._group["BOS-002"]) {
                              audioRef.current._group["BOS-002"]._list[0].play(
                                "arrow"
                              );
                            }
                          }
                          if (pos === 1) {
                            if (audioRef.current._group["BOS-002"]) {
                              audioRef.current._group["BOS-002"]._list[0].play(
                                "arrowHit"
                              );
                            }
                            bulletController.containerArrow.visible = false;
                            bulletController.DmitriArrow.container.visible = false;
                            bulletController.DmitriArrow.emitter.emit = false;
                          } else {
                            bulletController.DmitriArrow.container.visible = true;
                            bulletController.DmitriArrow.emitter.emit = true;
                            bulletController.DmitriArrow.emitter.update(dt);
                            bulletController.containerArrow.position.x =
                              -75 + (targetPosition.x + 75) * pos;
                            bulletController.containerArrow.position.y =
                              -360 + pos ** 4 * 330;
                            bulletController.DmitriArrow.container.angle =
                              -60 * pos ** (1 / 2);
                          }
                        })
                      )
                      .play();
                  });
                  if (audioRef.current._group["BOS-002"]) {
                    audioRef.current._group["BOS-002"]._list[0].play(
                      "mammothRoar"
                    );
                    setTimeout(() => {
                      audioRef.current._group["BOS-002"]._list[0].play("stomp");
                    }, 500);
                    setTimeout(() => {
                      audioRef.current._group["BOS-002"]._list[0].play(
                        "switch"
                      );
                      audioRef.current._group["BOS-002"]._list[0].play(
                        "mechanic"
                      );
                    }, 666);
                    setTimeout(() => {
                      audioRef.current._group["BOS-002"]._list[0].play(
                        "switch"
                      );
                      audioRef.current._group["BOS-002"]._list[0].play(
                        "penguinRoar"
                      );
                    }, 833);
                  }
                  await character.controller.routineWait(1.5);
                },
                finalAttack: async (self, allTargetPlayer) => {
                  // console.log("final attack from boss");
                  stompParticle.position.set(0, -20);
                  let tweener = new Tweener(app.ticker);
                  let initialTime = 0.566;
                  let spikeDuration = 2;

                  tweener
                    .insert(
                      0.566,
                      new Tween(2.75, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.StompImpact.container.visible = false;
                          emitterController.StompImpact.emitter.emit = false;
                          emitterController.StompImpactDust.container.visible = false;
                          emitterController.StompImpactDust.emitter.emit = false;
                        } else {
                          emitterController.StompImpact.container.visible = true;
                          emitterController.StompImpact.emitter.emit = true;
                          emitterController.StompImpact.emitter.update(dt);
                          emitterController.StompImpactDust.container.scale.set(
                            1 + pos ** 2 * 0.5
                          );
                          emitterController.StompImpactDust.container.position.y =
                            0 + pos ** 2 * -10;
                          emitterController.StompImpact.container.alpha =
                            1 + pos ** 2 * -1;

                          emitterController.StompImpactDust.container.visible = true;
                          emitterController.StompImpactDust.emitter.emit = true;
                          emitterController.StompImpactDust.emitter.update(dt);
                          emitterController.StompImpactDust.container.scale.set(
                            1 + pos ** 2 * 0.5
                          );
                          emitterController.StompImpactDust.container.position.y =
                            0 + pos ** 2 * -10;
                          emitterController.StompImpactDust.container.alpha =
                            1 + pos ** 4 * -1;
                        }
                      })
                    )
                    .insert(
                      0.666,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.StompImpactParticle.container.visible = false;
                          emitterController.StompImpactParticle.emitter.emit = false;
                        } else {
                          emitterController.StompImpactParticle.container.visible = true;
                          emitterController.StompImpactParticle.emitter.emit = true;
                          emitterController.StompImpactParticle.emitter.update(
                            dt
                          );
                          emitterController.StompImpactParticle.container.scale.set(
                            1 + pos ** 2 * 5
                          );
                          emitterController.StompImpactParticle.container.position.y =
                            0 + pos ** 2 * -25;
                          emitterController.StompImpactParticle.container.alpha =
                            1 + pos ** 2 * -1;
                        }
                      })
                    );

                  emitterController.allSpikes.forEach((spike, index) => {
                    spike.position.x = -120 * (index + 1) - 120;
                    let iceSpikeController = spike.controller;
                    // console.log("iceSpikeController", iceSpikeController);
                    if (index % 2 === 1) {
                      iceSpikeController.SpikeImpactDust.container.scale.x = -1;
                      iceSpikeController.SpikeImpactParticle.container.scale =
                        -1;
                    }

                    tweener.insert(
                      initialTime + index * 0.075,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["BOS-002"]) {
                            audioRef.current._group["BOS-002"]._list[0].play(
                              "iceHit"
                            );
                            audioRef.current._group["BOS-002"]._list[0].play(
                              "iceCrack"
                            );
                          }
                        }
                        if (pos === 1) {
                          iceSpikeController.iceSpike.container.visible = false;
                          iceSpikeController.iceSpike.emitter.emit = false;
                        } else {
                          iceSpikeController.iceSpike.container.visible = true;
                          iceSpikeController.iceSpike.emitter.emit = true;
                          iceSpikeController.iceSpike.emitter.update(dt);
                          iceSpikeController.iceSpike.container.scale.set(
                            0 + pos ** 4 * 2
                          );
                          iceSpikeController.iceSpike.container.position.y =
                            0 + pos ** 4 * -100;
                        }
                      })
                    );
                    tweener.insert(
                      initialTime + index * 0.1,
                      new Tween(spikeDuration, (pos, dt) => {
                        if (pos === 1) {
                          iceSpikeController.SpikeImpactDust.container.visible = false;
                          iceSpikeController.SpikeImpactDust.emitter.emit = false;
                          iceSpikeController.SpikeImpactParticle.container.visible = false;
                          iceSpikeController.SpikeImpactParticle.emitter.emit = false;
                        } else {
                          iceSpikeController.SpikeImpactDust.container.visible = true;
                          iceSpikeController.SpikeImpactDust.emitter.emit = true;
                          iceSpikeController.SpikeImpactDust.emitter.update(dt);

                          iceSpikeController.SpikeImpactDust.container.scale.set(
                            1 + pos ** 2 * 1
                          );
                          iceSpikeController.SpikeImpactDust.container.position.y =
                            -5 + pos ** 2 * -20;
                          iceSpikeController.SpikeImpactDust.container.alpha =
                            1 + pos ** 4 * -1;

                          iceSpikeController.SpikeImpactParticle.container.visible = true;
                          iceSpikeController.SpikeImpactParticle.emitter.emit = true;
                          iceSpikeController.SpikeImpactParticle.emitter.update(
                            dt
                          );
                          iceSpikeController.SpikeImpactParticle.container.scale.set(
                            1 + pos ** 2 * 5
                          );
                          iceSpikeController.SpikeImpactParticle.container.position.y =
                            0 + pos ** 2 * -25;
                          iceSpikeController.SpikeImpactParticle.container.alpha =
                            1 + pos ** 2 * -1;
                        }
                      })
                    );
                  });

                  tweener.play();
                  if (audioRef.current._group["BOS-002"]) {
                    audioRef.current._group["BOS-002"]._list[0].play(
                      "mammothRoar"
                    );
                    setTimeout(() => {
                      audioRef.current._group["BOS-002"]._list[0].play(
                        "penguinRoar"
                      );
                    }, 333);
                    setTimeout(() => {
                      audioRef.current._group["BOS-002"]._list[0].play("stomp");
                    }, 500);
                  }
                  await character.controller.routineWait(1.5);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["BOS-002"]) {
                      audioRef.current._group["BOS-002"]._list[0].play(
                        "mammothDead"
                      );
                      setTimeout(() => {
                        audioRef.current._group["BOS-002"]._list[0].play(
                          "penguinDead"
                        );
                      }, 3000);
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-002"])
                    audioRef.current._group["BOS-002"]._list[0].play(
                      "penguinTalk"
                    );
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_DMITRI" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
