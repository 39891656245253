import * as PIXI from "pixi.js";

import curse_1 from "./curse_1.png";
import curse_2 from "./curse_2.png";
import curse_3 from "./curse_3.png";

import bite00 from "./Kosmo Particle-animation_00.png";
import bite01 from "./Kosmo Particle-animation_01.png";
import bite02 from "./Kosmo Particle-animation_02.png";
import bite03 from "./Kosmo Particle-animation_03.png";
import bite04 from "./Kosmo Particle-animation_04.png";
import bite05 from "./Kosmo Particle-animation_05.png";
import bite06 from "./Kosmo Particle-animation_06.png";
import bite07 from "./Kosmo Particle-animation_07.png";
import bite08 from "./Kosmo Particle-animation_08.png";
import bite09 from "./Kosmo Particle-animation_09.png";
import bite10 from "./Kosmo Particle-animation_10.png";
import bite12 from "./Kosmo Particle-animation_11.png";
import bite11 from "./Kosmo Particle-animation_12.png";
import bite13 from "./Kosmo Particle-animation_13.png";
import bite14 from "./Kosmo Particle-animation_14.png";
import bite15 from "./Kosmo Particle-animation_15.png";
import bite16 from "./Kosmo Particle-animation_16.png";
import bite17 from "./Kosmo Particle-animation_17.png";
import bite18 from "./Kosmo Particle-animation_18.png";
import bite19 from "./Kosmo Particle-animation_19.png";
import bite20 from "./Kosmo Particle-animation_20.png";
import bite21 from "./Kosmo Particle-animation_21.png";
import bite22 from "./Kosmo Particle-animation_22.png";
import bite23 from "./Kosmo Particle-animation_23.png";
import bite24 from "./Kosmo Particle-animation_24.png";
import bite25 from "./Kosmo Particle-animation_25.png";
import bite26 from "./Kosmo Particle-animation_26.png";
import bite27 from "./Kosmo Particle-animation_27.png";

const KosmoParticle = {
  createAnimatedSprite: () => {
    const biteTexture = [
      bite00,
      bite01,
      bite02,
      bite03,
      bite04,
      bite05,
      bite06,
      bite07,
      bite08,
      bite09,
      bite10,
      bite11,
      bite12,
      bite13,
      bite14,
      bite15,
      bite16,
      bite17,
      bite18,
      bite19,
      bite20,
      bite21,
      bite22,
      bite23,
      bite24,
      bite25,
      bite26,
      bite27,
    ];
    return new Promise((resolve, reject) => {
      let textureArr = [];
      for (let i = 0; i < biteTexture.length; i++) {
        const texture = PIXI.Texture.from(biteTexture[i]);
        // console.log(texture);
        textureArr.push(texture);
      }
      const biteAnimate = new PIXI.AnimatedSprite(textureArr);
      biteAnimate.loop = false;
      biteAnimate.anchor.set(0.5);
      // biteAnimate.scale.set(0.5);

      const container = new PIXI.Container();
      container.addChild(biteAnimate);
      resolve({ container, biteAnimate });
    });
  },
  load: ({ app, bulletParticle, containerParticle }) => {
    return new Promise(async (resolve, reject) => {
      let allParticle = {};
      let allBullet = [];
      let allCurseWord = [];

      const curse1Texture = PIXI.Texture.from(curse_1);
      const curse2Texture = PIXI.Texture.from(curse_2);
      const curse3Texture = PIXI.Texture.from(curse_3);

      for (let index = 0; index < 5; index++) {
        let bulletContainer = new PIXI.Container();
        bulletParticle.addChild(bulletContainer);
        bulletContainer.controller = {};
        let container = new PIXI.Container();
        bulletContainer.addChild(container);

        await KosmoParticle.createAnimatedSprite().then(
          ({ container, biteAnimate }) => {
            biteAnimate.visible = false;

            bulletContainer.addChild(container);
            bulletContainer.controller = {
              ...bulletContainer.controller,
              container,
              biteAnimate,
            };
          }
        );

        bulletContainer.controller = {
          ...bulletContainer.controller,
          container,
        };

        allBullet.push(bulletContainer);
      }

      allParticle = { ...allParticle, allBullet };

      for (let index = 0; index < 300; index++) {
        const curseContainer = new PIXI.Sprite(
          index % 3 === 0
            ? curse1Texture
            : index % 3 === 1
            ? curse2Texture
            : curse3Texture
        );
        curseContainer.anchor.set(0.5);
        curseContainer.scale.set(0.01 + Math.random() * 0.1);
        curseContainer.visible = false;

        containerParticle.addChild(curseContainer);
        allCurseWord.push(curseContainer);
      }

      allParticle = { ...allParticle, allCurseWord };

      resolve(allParticle);

      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default KosmoParticle;
