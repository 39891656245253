import {
  Box,
  ChallengeCardBox,
  TextTypo,
  BottomBar,
  ChallengeBox,
  ButtonAction,
  DrawerQA,
  TextParagraph,
  SayBox,
} from "Game/Style/GameStyle";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Topbar from "Game/Component/SideComponents/Topbar";
import { useVonderGoContext } from "Service/VonderGOContext";
import TimeBox from "Game/Component/SideComponents/TimeBox";
import AnswerCountBox from "Game/Component/SideComponents/AnswerCountBox";
import CardContent from "Game/Component/Common/CardContent";
import Leaderboard from "Game/Component/GamePage/Leaderboard";
import AnswerResult from "Game/Component/GamePage/AnswerResult";
import Footer from "Game/Component/SideComponents/Footer";
import QApage from "Game/Component/GamePage/QApage";
import GameResult from "Game/Component/GamePage/GameResult";
import uniq from "lodash/uniq";
import QABox from "./SideComponents/QABox";
import { AppContext } from "Game/AppContext";
import styled from "styled-components";
import { useLocalizationContext } from "Service/LocalizationProvider";
import useBossSay from "Hooks/useBossSay";
import MediaModal from "Game/Component/Common/MediaModal";
import { Space } from "antd";
import useCountdownTimer from "Hooks/useCountdownNumber";

export default memo(function GameBody() {
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [bossWalked, setBossWalked] = useState(false);
  const { t } = useLocalizationContext();
  const {
    currentGameState,
    dispatchGameState,
    wsSend,
    qaList,
    setQAList,
    animationState,
    gameContextState,
    userLike,
    currentGameData,
    dispatchAnimationState,
    showMediaModal,
    setShowMediaModal,
    playSFX,
    currentPhase,
  } = useVonderGoContext();
  console.log(currentPhase);
  const {
    isReadyToPlay,
    isBossSay,
    isShowChallenge,
    isShowBoss,
    finalBossSay,
    setIsShowBoss,
  } = useContext(AppContext);
  const { beginTypeWriter, finalTypeWriter } = useBossSay();
  const [startButtonDisable, setStartButtonDisable] = useState(false);

  const findBossOut = (80 / 100) * currentGameData?.totalQuestion;

  //#region micro content controller
  const [currentMicroContentSequence, setCurrentMicroContentSequence] =
    useState(0);
  const [maxContentSequece, setMaxContentSequece] = useState(0);

  const [countdown, timeLeft] = useCountdownTimer(
    () => setStartButtonDisable(false),
    5,
    currentPhase
  );

  useEffect(() => {
    setMaxContentSequece(currentGameData?.microContents?.length);
    if (
      currentGameData?.microContents?.length !== 0 &&
      currentGameState?.showMicroContent
    ) {
      setCurrentMicroContentSequence(1);
    } else {
      setCurrentMicroContentSequence(0);
    }
  }, [currentGameData?.microContents?.length, currentGameState?.showMicroContent]);

  useEffect(() => {
    if (
      currentGameState?.showMicroContent &&
      currentMicroContentSequence !== 0
    ) {
      wsSend(
        JSON.stringify({
          event: "sync-micro-contents-seq",
          seq: currentMicroContentSequence,
        })
      );
    }
    // console.log("currentMicroContentSequence", currentMicroContentSequence);
  }, [currentGameState?.showMicroContent, currentMicroContentSequence, wsSend]);
  const onBackMicroContentSequence = useCallback(() => {
    setCurrentMicroContentSequence((prev) =>
      prev > 0 ? prev - 1 : maxContentSequece
    );
  }, [maxContentSequece]);
  const onNextMicroContentSequence = useCallback(() => {
    setCurrentMicroContentSequence((prev) =>
      prev === maxContentSequece ? 0 : prev + 1
    );
    setStartButtonDisable(false);
  }, [maxContentSequece]);

  //#endregion

  //#region if load asset complete, open loading black circle
  useEffect(() => {
    if (isReadyToPlay) {
      dispatchAnimationState({ type: "sword_loading", payload: false });
      dispatchAnimationState({ type: "lobby_to_gameplay", payload: "play" });
      setTimeout(() => {
        dispatchAnimationState({
          type: "lobby_to_gameplay",
          payload: "paused",
        });
      }, 1000);
    }
  }, [dispatchAnimationState, isReadyToPlay]);
  //#endregion

  //#region set new all doubt to show in Q&A
  useEffect(() => {
    if (gameContextState?.allDoubt) {
      let questionIndexList = uniq(
        gameContextState?.allDoubt?.map((data) => data?.questionIndex)
      ).map((num) => {
        return {
          questionIndex: num,
          doubtList: [],
        };
      });
      gameContextState?.allDoubt?.forEach((element) => {
        const question = questionIndexList.find(
          (val) => val?.questionIndex === element.questionIndex
        );
        question.doubtList.push(element);
      });
      dispatchAnimationState({ type: "doubt_card_visible", payload: true });
      setQAList(questionIndexList);
    }
  }, [gameContextState, setQAList, dispatchAnimationState]);
  //#endregion

  //#region set user like doubt in Q&A
  useEffect(() => {
    if (userLike) {
      setQAList((prev) => {
        const findQA = prev?.find(
          (item) => item?.questionIndex === userLike?.questionIndex
        );
        const findDoubtIndex = findQA?.doubtList?.findIndex(
          (doubt) => doubt?.id === userLike?.id
        );
        const action = userLike?.isLike ? +1 : -1;
        findQA.doubtList[findDoubtIndex].likeCount += action;
        wsSend(
          JSON.stringify({
            event: "doubt-like-count",
            id: findQA?.doubtList[findDoubtIndex]?.id,
            questionIndex: findQA?.doubtList[findDoubtIndex]?.questionIndex,
            likeCount: findQA?.doubtList[findDoubtIndex]?.likeCount,
          })
        );
        return [...prev];
      });
    }
  }, [setQAList, userLike, wsSend]);
  //#endregion

  //#region set show final leaderboard
  useEffect(() => {
    if (currentGameState?.finalLeaderboard) {
      setShowLeaderboard(true);
    }
  }, [currentGameState]);
  //#endregion

  //#region manage show component in gameplay, e.g., boss say box, progress bar, challenge card, button
  useEffect(() => {
    if (currentGameState?.stanby && isShowChallenge) {
      if (currentGameData?.questionIndex === 1) {
        if (isBossSay) {
          dispatchAnimationState({ type: "boss_say", payload: "in" });
        }
        dispatchAnimationState({ type: "show_progress_bar", payload: true });
      }
      // if (currentGameData?.questionIndex !== 1) {
      //   dispatchAnimationState({ type: "show_progress_bar", payload: false });
      // }
      if (currentGameData?.questionIndex === Math.ceil(findBossOut)) {
        if (bossWalked) {
          dispatchAnimationState({
            type: "show_challenge_card",
            payload: "up",
          });
        } else {
          dispatchAnimationState({ type: "show_challenge_card", payload: "" });
        }
      }
      if (!currentGameState?.showAnswerResult && isShowBoss && !bossWalked) {
        if (finalBossSay) {
          dispatchAnimationState({ type: "final_boss_say", payload: "in" });
        }
        dispatchAnimationState({ type: "button_visible", payload: false });
      } else {
        dispatchAnimationState({ type: "show_challenge_card", payload: "up" });
        dispatchAnimationState({ type: "button_visible", payload: true });
        dispatchAnimationState({
          type: "close_challenge_info",
          payload: false,
        });
        dispatchAnimationState({ type: "close_side_card", payload: false });
      }
    }

    if (
      (currentGameState?.showQuestionType &&
        currentGameData?.questionIndex !== 1) ||
      currentGameState?.showGameResult
    ) {
      dispatchAnimationState({ type: "show_progress_bar", payload: true });
    }
  }, [currentGameState, dispatchGameState, currentGameData, dispatchAnimationState, isBossSay, isShowChallenge, isShowBoss, finalBossSay, findBossOut, bossWalked]);
  //#endregion

  //#region check time boss say
  useEffect(() => {
    if (isBossSay) {
      dispatchAnimationState({ type: "boss_say", payload: "in" });
      setTimeout(() => {
        beginTypeWriter();
      }, 800);
      setTimeout(() => {
        dispatchAnimationState({ type: "boss_say", payload: "out" });
      }, 5000);
    }
    if (finalBossSay) {
      dispatchAnimationState({ type: "final_boss_say", payload: "in" });
      setTimeout(() => {
        finalTypeWriter();
      }, 800);
      setTimeout(() => {
        dispatchAnimationState({ type: "final_boss_say", payload: "out" });
      }, 5000);
      setTimeout(() => {
        setBossWalked(true);
      }, 9000);
    }
  }, [beginTypeWriter, dispatchAnimationState, isBossSay, finalBossSay, finalTypeWriter, setIsShowBoss]);
  //#endregion

  //#region check time show progress bar and bottom bar
  useEffect(() => {
    if (animationState?.closeSideCard) {
      setTimeout(() => {
        dispatchAnimationState({
          type: "show_challenge_card",
          payload: "down",
        });
        dispatchAnimationState({ type: "show_progress_bar", payload: false });
      }, 500);
      setTimeout(() => {
        dispatchAnimationState({ type: "show_footer_bar", payload: true });
      }, 2200);
    }
  }, [animationState.closeSideCard, dispatchAnimationState]);
  //#endregion

  //#region start challenge function
  const onStartChallenge = useCallback(() => {
    // console.log("onStartChallenge");
    playSFX("major");
    setStartButtonDisable(true);
    countdown();
    setTimeout(() => {
      wsSend(JSON.stringify({ event: "micro-contents-ready" }));
    }, 300);
    dispatchAnimationState({ type: "show_progress_bar", payload: true });
    dispatchAnimationState({ type: "hide_choice", payload: false });
  }, [countdown, dispatchAnimationState, playSFX, wsSend]);

  const onStartQuestion = useCallback(() => {
    // console.log("onStartQuestionStart");
    playSFX("major");
    setStartButtonDisable(true);
    countdown();
    setTimeout(() => {
      wsSend(JSON.stringify({ event: "question-ready" }));
    }, 300);
    dispatchAnimationState({ type: "hide_choice", payload: false });
    dispatchAnimationState({ type: "close_challenge_info", payload: true });
  }, [playSFX, countdown, dispatchAnimationState, wsSend]);
  //#endregion

  //#region hide choice function
  const onClickHideChoice = useCallback(() => {
    playSFX("major");
    const visible = !animationState?.isHideChoice;
    dispatchAnimationState({ type: "hide_choice", payload: visible });
  }, [animationState?.isHideChoice, dispatchAnimationState, playSFX]);
  //#endregion

  //#region force end question function
  const endQuestion = useCallback(() => {
    console.log("explanation-ready sent");
    playSFX("major");
    setStartButtonDisable(true);
    countdown();
    dispatchAnimationState({ type: "close_side_card", payload: true });
    dispatchAnimationState({
      type: "close_question_to_answering",
      payload: false,
    });
    dispatchAnimationState({ type: "button_visible", payload: false });
    setTimeout(() => {
      wsSend(JSON.stringify({ event: "explanation-ready" }));
    }, 300);
  }, [countdown, dispatchAnimationState, playSFX, wsSend]);
  //#endregion

  //#region check show button in gameplay
  const showMicroContentButton = useMemo(() => {
    return (
      <Space size="small" direction="horizontal">
        {currentMicroContentSequence !== 1 && maxContentSequece !== 0 && (
          <ButtonAction
            size="large"
            type="default"
            onClick={onBackMicroContentSequence}
            visible={animationState?.actionButtonVisible?.toString()}
          >
            {t("BUTTON.BACK")}
          </ButtonAction>
        )}
        {currentMicroContentSequence !== 0 &&
          currentMicroContentSequence !== maxContentSequece && (
            <ButtonAction
              size="large"
              type="primary"
              onClick={onNextMicroContentSequence}
              visible={animationState?.actionButtonVisible?.toString()}
            >
              {t("BUTTON.NEXT")}
            </ButtonAction>
          )}
        {currentMicroContentSequence === maxContentSequece &&
          maxContentSequece !== 0 && (
            <ButtonAction
              size="large"
              type="primary"
              onClick={() => {
                setStartButtonDisable(true);
                currentGameState?.showAnswering
                  ? onNextMicroContentSequence()
                  : onStartQuestion();
              }}
              visible={animationState?.actionButtonVisible?.toString()}
              disabled={startButtonDisable}
              color={startButtonDisable ? "#2D3243" : undefined}
              onDoubleClick={() => {
                console.log("double click");
                return true;
              }}
            >
              {t("BUTTON.GO_TO_QUESTION")}{" "}
              {startButtonDisable && `(${timeLeft})`}
            </ButtonAction>
          )}
        {currentMicroContentSequence === 0 && (
          <ButtonAction
            size="large"
            type="primary"
            onClick={() => endQuestion()}
            visible={animationState?.actionButtonVisible?.toString()}
            disabled={startButtonDisable}
            color={startButtonDisable ? "#2D3243" : undefined}
            onDoubleClick={() => {
              console.log("double click");
              return true;
            }}
          >
            {t("BUTTON.END_THIS_QUESTION")}{" "}
            {startButtonDisable && `(${timeLeft})`}
          </ButtonAction>
        )}
      </Space>
    );
  }, [
    animationState?.actionButtonVisible,
    currentGameState?.showAnswering,
    currentMicroContentSequence,
    endQuestion,
    maxContentSequece,
    onBackMicroContentSequence,
    onNextMicroContentSequence,
    onStartQuestion,
    startButtonDisable,
    t,
    timeLeft,
  ]);
  const showButton = useMemo(() => {
    if (currentGameState?.stanby) {
      return (
        <ButtonAction
          size="small"
          type="primary"
          onClick={() => {
            onStartChallenge();
          }}
          visible={animationState?.actionButtonVisible?.toString()}
          disabled={startButtonDisable}
          color={startButtonDisable ? "#2D3243" : undefined}
          onDoubleClick={() => {
            console.log("double click");
            return true;
          }}
        >
          {t("BUTTON.GO")} {startButtonDisable && `(${timeLeft})`}
        </ButtonAction>
      );
    }

    if (currentGameState?.showMicroContent || currentGameState?.showAnswering) {
      return <>{showMicroContentButton}</>;
    }
    // if (currentGameState?.showAnswering) {
    //   return (
    //     <ButtonAction
    //       size="large"
    //       type="primary"
    //       onClick={endQuestion}
    //       visible={animationState?.actionButtonVisible?.toString()}
    //     >
    //       {t("BUTTON.END_THIS_QUESTION")}
    //     </ButtonAction>
    //   );
    // }
  }, [
    currentGameState?.stanby,
    currentGameState?.showMicroContent,
    currentGameState?.showAnswering,
    animationState?.actionButtonVisible,
    startButtonDisable,
    t,
    timeLeft,
    onStartChallenge,
    showMicroContentButton,
  ]);
  //#endregion

  //#region check display content in challenge card
  const bodyContent = useMemo(() => {
    if (currentGameState?.showGameResult) {
      return <GameResult />;
    }
    if (showLeaderboard) {
      return <Leaderboard />;
    }
    if (currentGameState?.showAnswerResult) {
      return <AnswerResult />;
    }
    if (!currentGameState?.showAnswerResult) {
      return (
        <>
          <ChallengeBox
            direction="column"
            marginTop="5%"
            height={currentMicroContentSequence !== 0 ? "60%" : undefined}
          >
            <ChallengeCardBox
              justify="center"
              align="center"
              direction="column"
              visible={animationState?.challengeVisible}
            >
              <CardContent
                currentMicroContentSequence={currentMicroContentSequence}
              />
            </ChallengeCardBox>

            {currentGameState?.showAnswering && (
              <>
                <TimeBox />
                <AnswerCountBox />
                {currentMicroContentSequence === 0 && (
                  <BottomBar
                    justify="center"
                    align="center"
                    close={animationState?.closeSideCard}
                    onClick={onClickHideChoice}
                    style={{ zIndex: 1 }}
                  >
                    <TextTypo size="1.2vw" strong="true" type="secondary">
                      {animationState?.isHideChoice
                        ? t("GAMEPLAY.SHOW_CHOICE")
                        : t("GAMEPLAY.HIDE_CHOICE")}
                    </TextTypo>
                  </BottomBar>
                )}
              </>
            )}
          </ChallengeBox>
          <Box
            style={{ bottom: 0 }}
            height="10%"
            position="absolute"
            width="100%"
            justify="flex-end"
            align="center"
            padding="0.5% 3%"
          >
            {showButton}
          </Box>
        </>
      );
    }
  }, [
    currentGameState?.showGameResult,
    currentGameState?.showAnswerResult,
    currentGameState?.showAnswering,
    showLeaderboard,
    animationState?.challengeVisible,
    animationState?.closeSideCard,
    animationState?.isHideChoice,
    currentMicroContentSequence,
    onClickHideChoice,
    t,
    showButton,
  ]);
  //#endregion

  //#region check show footer
  const showFooter = useMemo(() => {
    if (
      (currentGameState?.showAnswerResult && animationState?.footerVisible) ||
      animationState?.showQA
    ) {
      return <Footer />;
    }
  }, [currentGameState, animationState]);
  //#endregion

  //#region Q&A drawer
  const qaDrawer = useMemo(
    () => (
      <DrawerQA
        title={
          <TextTypo size="1.5vw" strong="true">
            {t("GAMEPLAY.Q&A")}
          </TextTypo>
        }
        placement="top"
        closable={false}
        onClose={() =>
          dispatchAnimationState({ type: "show_qa", payload: false })
        }
        visible={animationState?.showQA}
      >
        <QApage qaList={qaList} setQAList={setQAList} />
        <Footer />
      </DrawerQA>
    ),
    [animationState.showQA, dispatchAnimationState, qaList, setQAList, t]
  );
  //#endregion

  //#region check boss say
  const bossSay = useMemo(() => {
    if (currentGameState?.stanby && currentGameData?.questionIndex === 1) {
      return (
        <SayBox visible={animationState?.bossSay}>
          <TextParagraph id="intro" size="1.5vw" strong="true" align="start" />
        </SayBox>
      );
    }
    if (
      !currentGameState?.showAnswerResult &&
      currentGameState?.stanby &&
      isShowBoss
    ) {
      return (
        <SayBox visible={animationState?.finalBossSay}>
          <TextParagraph id="final" size="1.5vw" strong="true" align="start" />
        </SayBox>
      );
    }
  }, [currentGameState, currentGameData, isShowBoss, animationState]);
  //#endregion

  return (
    <>
      <Topbar showLeaderboard={showLeaderboard} />
      <MediaModal visible={showMediaModal} setVisible={setShowMediaModal} />
      <ChallengeContainer>
        <QABox />
        <Box position="absolute" style={{ top: "25%", left: "13%" }}>
          {bossSay}
        </Box>
        {bodyContent}
      </ChallengeContainer>
      {showFooter}
      {qaDrawer}
    </>
  );
});

const ChallengeContainer = styled(Box)`
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 93%;
  flex-direction: column;
  position: relative;
`;
