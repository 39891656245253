import {
  // ParallaxBlock,
  // ParallaxLayer,
  ParallaxLoop,
} from "Classes/Parallax.js";
import * as PIXI from "pixi.js";

const level = {
  areaLength: 4000,
  load: ({ app, assets }) => {
    // console.log(assets);
    return new Promise((resolve, reject) => {
      // console.log("assets", assets);
      const loader = new PIXI.Loader();
      let spriteList = [
        // { name: "sprites_multi_clouds" },
        { name: "sprites_multi_large_0" },
        { name: "sprites_multi_large_1" },
        { name: "sprites_multi_large_2" },
        { name: "sprites_multi_trees" },
      ];
      assets.forEach((element) => {
        // console.log("element", element);
        const path = element.link;
        const arr = path.split("/");
        const filename = arr[arr.length - 1].split(".");
        const type = filename[0];
        if (
          spriteList.find((sprite) => {
            return sprite.name === type;
          })
        ) {
          loader.add(type, element.link);
        }
      });

      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_clouds.json
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_clouds.png
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_0.json
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_0.png
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_1.json
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_1.png
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_2.json
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_2.png
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_trees.json
      // https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_trees.png

      // loader.add(
      //   "sprites_multi_large_0",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_0.json"
      // );
      // loader.add(
      //   "sprites_multi_large_1",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_1.json"
      // );
      // loader.add(
      //   "sprites_multi_large_2",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_large_2.json"
      // );
      // loader.add(
      //   "sprites_multi_trees",
      //   "https://dh8bdvjvmxojs.cloudfront.net/go/theme/Yosegoni/multi/sprites_multi_trees.json"
      // );

      loader.load((loader, resources) => {
        // console.log(resources);
        // const spritesheetTextureClouds =
        //   resources["sprites_multi_clouds"].spritesheet.textures;

        const spritesheetTextureLarge0 =
          resources["sprites_multi_large_0"].spritesheet.textures;
        const spritesheetTextureLarge1 =
          resources["sprites_multi_large_1"].spritesheet.textures;
        const spritesheetTextureLarge2 =
          resources["sprites_multi_large_2"].spritesheet.textures;
        const spritesheetTextureTrees =
          resources["sprites_multi_trees"].spritesheet.textures;

        // console.log("spritesheetTextureClonds", spritesheetTextureClouds);
        // console.log("spritesheetTextureLarge0", spritesheetTextureLarge0);
        // console.log("spritesheetTextureLarge1", spritesheetTextureLarge1);
        // console.log("spritesheetTextureLarge2", spritesheetTextureLarge2);
        // console.log("spritesheetTextureTrees", spritesheetTextureTrees);

        const parallax = new ParallaxLoop(new PIXI.Container());

        const BG = new PIXI.Sprite(
          spritesheetTextureLarge0["Yesegoni-Smaller_0016_Layer17_BG.png"]
        );
        BG.position.set(-960, -1010);
        BG.scale.set(1);

        const Cloud3 = new PIXI.Sprite(
          spritesheetTextureLarge2["Yesegoni-Smaller_0015_Layer16_Cloud3.png"]
        );
        Cloud3.position.set(120, -1010);
        // Cloud3.scale.set(2);

        const Cloud2 = new PIXI.Sprite(
          spritesheetTextureLarge2["Yesegoni-Smaller_0014_Layer15_Cloud2.png"]
        );
        Cloud2.position.set(-960, -1010);
        // Cloud2.scale.set(2);

        const Cloud1 = new PIXI.Sprite(
          spritesheetTextureLarge2["Yesegoni-Smaller_0013_Layer14_Cloud1.png"]
        );
        Cloud1.position.set(1800, -1010);
        // Cloud1.scale.set(2);

        const Mountain = new PIXI.Sprite(
          spritesheetTextureLarge1["Yesegoni-Smaller_0012_Layer13_Mountain.png"]
        );
        Mountain.position.set(880, -800);
        // Mountain.scale.set(1.1);

        const LeftCliff = new PIXI.Sprite(
          spritesheetTextureLarge1[
            "Yesegoni-Smaller_0011_Layer12_LeftCliff.png"
          ]
        );
        LeftCliff.position.set(-400, -350);
        // LeftCliff.scale.set(1.25);

        const RightCliff = new PIXI.Sprite(
          spritesheetTextureLarge1[
            "Yesegoni-Smaller_0010_Layer11_RightCliff.png"
          ]
        );
        RightCliff.position.set(1760, -550);
        // RightCliff.scale.set(1.25);

        const Tree5 = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0009_Layer10_Tree5.png"]
        );
        Tree5.position.set(870, -350);
        // Tree5.scale.set(2);

        const Tree4 = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0008_Layer9_Tree4.png"]
        );
        Tree4.position.set(1800, -600);
        // Tree4.scale.set(2);

        const GrassMiddle = new PIXI.Sprite(
          spritesheetTextureLarge2[
            "Yesegoni-Smaller_0007_Layer8_GrassMiddle.png"
          ]
        );
        GrassMiddle.position.set(600, -280);
        GrassMiddle.scale.set(1);

        const Baobab = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0006_Layer7_Baobab.png"]
        );
        Baobab.position.set(2200, -1010);
        Baobab.scale.set(1.15);

        const GrassRight = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0005_Layer6_GrassRight.png"]
        );
        GrassRight.position.set(2880, -270);
        // GrassRight.scale.set(2);

        const Tree3 = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0004_Layer5_Tree3.png"]
        );
        // Tree3.position.set(400, -580);
        Tree3.position.set(460, -580);

        const Tree2 = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0003_Layer4_Tree2.png"]
        );
        // Tree2.position.set(-135, -790);
        Tree2.position.set(-75, -790);

        const Tree1 = new PIXI.Sprite(
          spritesheetTextureTrees["Yesegoni-Smaller_0002_Layer3_Tree1.png"]
        );

        // Tree1.position.set(-950, -800);
        Tree1.position.set(-890, -800);

        const Castle = new PIXI.Sprite(
          spritesheetTextureLarge1["Yesegoni-Smaller_0001_Layer2_Castle.png"]
        );

        // Castle.position.set(-960, -720);
        Castle.position.set(-900, -720);

        const Floor = new PIXI.Sprite(
          spritesheetTextureLarge0["Yesegoni-Smaller_0000_Layer1_Ground.png"]
        );
        // Floor.position.set(-960, -168);
        Floor.position.set(-960, -160);
        Floor.scale.set(1);

        parallax.container.addChild(BG);
        parallax.container.addChild(Cloud3);
        parallax.container.addChild(Cloud2);
        parallax.container.addChild(Cloud1);

        parallax.container.addChild(Mountain);
        parallax.container.addChild(LeftCliff);
        parallax.container.addChild(RightCliff);
        parallax.container.addChild(Tree5);
        parallax.container.addChild(Tree4);
        parallax.container.addChild(GrassMiddle);
        parallax.container.addChild(Baobab);

        parallax.container.addChild(GrassRight);
        parallax.container.addChild(Tree3);
        parallax.container.addChild(Tree2);
        parallax.container.addChild(Tree1);
        parallax.container.addChild(Castle);
        parallax.container.addChild(Floor);

        const controller = {
          parallax,
          areaLength: level.areaLength,

          update: () => {
            parallax.updateParallaxPosition();
          },
          setSize: (size) => {
            parallax.setSize(size);
          },
          setPosition: (position) => {
            parallax.setPosition(position);
          },
          destroy: () => {
            parallax.destroy();
          },
        };
        resolve(controller);
      });
      loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default level;
