import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import BanditParticle from "Game/PixiCores/Particles/BanditParticle";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.1,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charging", loop: false },
      { name: "ChargingLoop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Charging", loop: false },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true }],
    lose: [
      { name: "Lose", loop: false },
      // { name: "Hit", loop: false },
      // { name: "Lose", loop: false, delay: 0 },
    ],
  },
  skins: ["Benjamin", "Bernard", "Brandon"],
  event: {
    Attack: () => {
      // character.controller.setFiredBulletTo(
      //   character.controller.targetPosition
      // );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add(
            "character",
            // process.env.REACT_APP_API_URL_GO_CHARACTER + "/BANDITS/Bandits.json"
            path
          );
          preload.load(async (loader, resources) => {
            // console.log("resources", resources);
            let spineData = resources["character"].spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);
              spine.skeleton.setSkinByName("Brandon");
              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);

              await BanditParticle.load(app, containerParticle).then(
                (particle) => {
                  emitterController = particle;
                }
              );
              const controller = {
                spine,
                container,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                infoCharacter: {
                  characterName: "brandon",
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                getDisappearedContainer: () => {
                  return emitterController.disappeared;
                },
                setDisappearedParticleActive: () => {
                  containerParticle.visible = true;

                  new Tweener(app.ticker)
                    .insert(
                      0,
                      new Tween(1, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["ENM-003"]) {
                            audioRef.current._group["ENM-003"]._list[0].play(
                              "dead"
                            );
                          }
                        }
                        if (pos === 1) {
                          containerParticle.visible = false;
                          emitterController.disappeared.emitter.emit = false;
                          emitterController.disappeared.emitter.cleanup();
                        }
                        emitterController.disappeared.emitter.emit = true;
                        emitterController.disappeared.emitter.update(dt);
                      })
                    )
                    .play();
                },
                setFiredBulletTo: async (targetPosition) => {
                  containerParticle.visible = true;
                  emitterController.banditBlade.emitter.cleanup();

                  if (!emitterController.banditBlade.container.visible)
                    emitterController.banditBlade.container.visible = true;
                  new Tweener(app.ticker)
                    .insert(
                      0.5,
                      new Tween(0.25, (pos, dt) => {
                        if (pos === 0) {
                          audioRef.current._group["ENM-003"]._list[0].play(
                            "attack"
                          );
                        }
                        if (pos === 1) {
                          audioRef.current._group["ENM-003"]._list[0].play(
                            "hitTarget"
                          );
                          emitterController.banditBlade.container.visible = false;
                          // emitterController.banditBlade.emitter.cleanup();
                        } else {
                          emitterController.banditBlade.container.position.x =
                            pos * targetPosition.x;
                          emitterController.banditBlade.emitter.emit = true;
                          emitterController.banditBlade.emitter.update(dt);
                        }
                      })
                    )
                    .play();
                  await character.controller.routineWait(0.75);
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                  containerParticle.visible = false;
                },
                setSkin: (skinName) => {
                  if (character.skins.indexOf(skinName) < 0) {
                    return false;
                  } else {
                    const skeleton = controller.spine.skeleton;
                    skeleton.setSkin(null);
                    skeleton.setSkinByName(skinName);
                    return true;
                  }
                },
                randomSkin: () => {
                  let index = Math.floor(
                    Math.random() * character.skins.length
                  );
                  controller.setSkin(character.skins[index]);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                },
                destroy: () => {
                  controller.spine?.destroy();
                  controller.container?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
