import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import AshokaParticle from "Game/PixiCores/Particles/Ashoka/AshokaParticle";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      //   character.controller.setAttackTo(character.controller.targetPosition);
    },
    AttackFinal: () => {
      //   character.controller.setFinalAttackTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: async (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            // console.log(spineData);
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let bulletParticle = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(bulletParticle);

              containerParticle.addChild(bulletParticle);
              bulletParticle.visible = false;
              await AshokaParticle.load(app, bulletParticle).then(
                (particle) => {
                  emitterController = particle;
                  // console.log(emitterController);
                }
              );

              const controller = {
                spine,
                container,
                containerParticle,
                bulletParticle,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "tenko",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                setAttackTo: (targetPosition) => {},
                setFinalAttackTo: (targetPosition) => {},
                attackPlayer: async (self, allTargetPlayer) => {
                  bulletParticle.visible = false;
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    // console.log("bulletController", bulletController);
                    bulletController.fireAnimated1.fire.play();
                    bulletController.fireAnimated2.fire.play();
                    bulletController.fireAnimated3.fire.play();
                    bulletController.container.angle = 45;
                    new Tweener(app.ticker)
                      .insert(
                        0.5,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 0) {
                            bulletController.container.visible = true;
                            bulletParticle.visible = true;
                            if (audioRef.current._group["BOS-004"]) {
                              audioRef.current._group["BOS-004"]._list[0].play(
                                "attack"
                              );
                            }
                          }
                          if (pos === 1) {
                            if (audioRef.current._group["BOS-004"]) {
                              audioRef.current._group["BOS-004"]._list[0].play(
                                "hitTarget"
                              );
                            }
                            bulletController.container.visible = false;
                            bulletParticle.visible = false;
                          } else {
                            bulletController.fireAnimated1.fire.update(dt);
                            bulletController.fireAnimated2.fire.update(dt);
                            bulletController.fireAnimated3.fire.update(dt);
                            bulletController.container.position.x =
                              -75 + (targetPosition.x + 25) * pos;
                            bulletController.container.position.y =
                              -200 + pos * (targetPosition.y + 300);
                          }
                        })
                      )
                      .play();
                  });
                  if (audioRef.current._group["BOS-002"]) {
                  }

                  await character.controller.routineWait(1);
                },
                finalAttack: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let bulletController =
                      emitterController.allBullet[index].controller;
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };
                    // console.log("targetPosition", targetPosition);
                    // console.log(
                    //   "self.container.position",
                    //   self.container.position
                    // );
                    // console.log("bulletController", bulletController);
                    bulletController.fireAnimated1.fire.play();
                    bulletController.fireAnimated2.fire.play();
                    bulletController.fireAnimated3.fire.play();
                    bulletController.fireAnimated1.container.scale.set(1.5);
                    bulletController.fireAnimated2.container.scale.set(1.25);
                    bulletController.fireAnimated3.container.scale.set(1);
                    // bulletController.container.angle = 45;
                    // bulletController.container.visible = true;
                    bulletController.container.pivot.set(0.5, 1);
                    bulletController.container.scale.set(0);
                    bulletController.container.position.y = -400;
                    bulletController.container.position.x = 0;
                    bulletController.container.angle = 0;

                    if (index === 1) {
                      bulletController.container.position.x = 150;
                      bulletController.container.position.y = -350;

                      bulletController.container.angle = 30;
                    }
                    if (index === 2) {
                      bulletController.container.position.x = -150;
                      bulletController.container.position.y = -350;
                      bulletController.container.angle = -30;
                    }
                    if (index === 3) {
                      bulletController.container.position.x = 200;
                      bulletController.container.position.y = -250;
                      bulletController.container.angle = 60;
                    }
                    if (index === 4) {
                      bulletController.container.position.x = -200;
                      bulletController.container.position.y = -250;
                      bulletController.container.angle = -60;
                    }

                    new Tweener(app.ticker)
                      .insert(
                        0.6,
                        new Tween(2.4, (pos, dt) => {
                          if (pos === 0) {
                            if (audioRef.current._group["BOS-004"]) {
                              audioRef.current._group["BOS-004"]._list[0].play(
                                "finalFire"
                              );
                              audioRef.current._group["BOS-004"]._list[0].play(
                                "talk"
                              );
                            }
                            bulletController.container.visible = true;
                            bulletParticle.visible = true;
                          }
                          if (pos === 1) {
                          } else {
                            bulletController.fireAnimated1.fire.update(dt);
                            bulletController.fireAnimated2.fire.update(dt);
                            bulletController.fireAnimated3.fire.update(dt);
                            bulletController.container.alpha = pos;
                            bulletController.container.scale.set(pos);
                          }
                        })
                      )
                      .insert(
                        3,
                        new Tween(0.5, (pos, dt) => {
                          if (pos === 0) {
                            bulletController.container.visible = true;
                            bulletParticle.visible = true;
                            bulletController.container.angle = 45;
                          }
                          if (pos === 1) {
                          } else {
                            bulletController.fireAnimated1.fire.update(dt);
                            bulletController.fireAnimated2.fire.update(dt);
                            bulletController.fireAnimated3.fire.update(dt);

                            bulletController.container.position.x =
                              //   (targetPosition.x - 115) * pos;
                              (targetPosition.x - 50) * pos;
                            bulletController.container.position.y =
                              -200 + pos * (targetPosition.y + 300);
                          }
                        })
                      )
                      .insert(
                        3.5,
                        new Tween(10, (pos, dt) => {
                          if (pos === 0) {
                            bulletController.container.visible = true;
                            bulletParticle.visible = true;
                            bulletController.container.angle = 45;
                            // bulletController.container.angle = 0;
                            bulletController.container.position.x =
                              //   (targetPosition.x - 115) * pos;
                              targetPosition.x - 50;
                            bulletController.container.position.y =
                              -200 + (targetPosition.y + 300);
                          }
                          if (pos === 1) {
                            bulletController.container.visible = false;
                            bulletParticle.visible = false;
                            bulletController.fireAnimated1.container.scale.set(
                              1
                            );
                            bulletController.fireAnimated2.container.scale.set(
                              1
                            );
                            bulletController.fireAnimated3.container.scale.set(
                              1
                            );
                            bulletController.container.scale.set(1);
                          } else {
                            bulletController.fireAnimated1.fire.update(dt);
                            bulletController.fireAnimated2.fire.update(dt);
                            bulletController.fireAnimated3.fire.update(dt);
                          }
                        })
                      )
                      .play();
                  });
                  if (audioRef.current._group["BOS-004"]) {
                    setTimeout(() => {
                      if (audioRef.current._group["BOS-004"]) {
                        audioRef.current._group["BOS-004"]._list[0].play(
                          "whip"
                        );
                      }
                      if (audioRef.current._group["BOS-004"]) {
                        audioRef.current._group["BOS-004"]._list[0].play(
                          "attack"
                        );
                      }
                    }, 3000);
                    setTimeout(() => {
                      if (audioRef.current._group["BOS-004"]) {
                        audioRef.current._group["BOS-004"]._list[0].play(
                          "hitTarget"
                        );
                      }
                      if (audioRef.current._group["BOS-004"]) {
                        audioRef.current._group["BOS-004"]._list[0].play(
                          "finalFire"
                        );
                      }
                    }, 3500);
                  }
                  await character.controller.routineWait(3.5);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["BOS-004"]) {
                      audioRef.current._group["BOS-004"]._list[0].play("dead");
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-004"])
                    audioRef.current._group["BOS-004"]._list[0].play("talk");
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_ASHOKA" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
