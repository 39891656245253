import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import XarathrustraParticle from "Game/PixiCores/Particles/Xarathrustra/XarathrustraParticle.js";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

const character = {
  controller: {},
  scale: 0.25,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [
      { name: "Win", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      //   character.controller.setAttackTo(character.controller.targetPosition);
    },
    AttackFinal: () => {
      //   character.controller.setFinalAttackTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: async (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            // console.log(spineData);
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();
              let bulletParticle = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);
              container.addChild(bulletParticle);

              containerParticle.addChild(bulletParticle);

              await XarathrustraParticle.load({
                app,
                bulletParticle,
                containerParticle,
              }).then((particle) => {
                emitterController = particle;
                // console.log(emitterController);
              });

              const controller = {
                spine,
                container,
                containerParticle,
                bulletParticle,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "Xarathrustra",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                setAttackTo: (targetPosition) => {},
                setFinalAttackTo: (targetPosition) => {},
                attackPlayer: async (self, allTargetPlayer) => {
                  allTargetPlayer.forEach((player, index) => {
                    let targetPosition = {
                      x:
                        player.container.position.x - self.container.position.x,
                      y: player.container.position.y,
                    };

                    let selectedLaser = 0;
                    switch (targetPosition.x) {
                      case -700:
                        selectedLaser = 0;
                        break;
                      case -900:
                        selectedLaser = 1;
                        break;
                      case -1100:
                        selectedLaser = 2;
                        break;
                      case -1300:
                        selectedLaser = 3;
                        break;
                      case -1500:
                        selectedLaser = 4;
                        break;
                      default:
                        selectedLaser = 0;
                        break;
                    }

                    let bulletController =
                      emitterController.allBullet[selectedLaser].controller;

                    // XarathrustraParticle
                    // console.log("bulletController", bulletController);
                    // console.log("targetPosition x", targetPosition.x);
                    bulletController.container.visible = true;
                    bulletController.container.position.set(0, 0);

                    // bulletController.smallLaserContainer.visible = true;
                    // bulletController.smallLaserContainer.angle =
                    //   -15 + index * 2;
                    // bulletController.smallLaserContainer.scale.x =
                    //   index * 0.3 - 0.1;
                    // bulletController.laserImpactContainer.position.set(
                    //   targetPosition.x,
                    //   targetPosition.y
                    // );
                    new Tweener(app.ticker)
                      .insert(
                        1.0,
                        new Tween(0.25, (pos, dt) => {
                          if (pos === 0) {
                            if (audioRef.current._group["BOS-006"]) {
                              audioRef.current._group["BOS-006"]._list[0].play(
                                "attack"
                              );
                            }
                            bulletController.smallLaserContainer.visible = true;
                          } else if (pos === 1) {
                            // bulletController.smallLaserContainer.angle =
                            //   -16 + index * 2.5 - index * 0.1;
                            bulletController.smallLaserContainer.scale.x =
                              0.35 + selectedLaser * 0.1;
                          } else {
                            // bulletController.smallLaserContainer.angle =
                            //   -16 + index * 2.5 - index * 0.1;
                            bulletController.smallLaserContainer.scale.x =
                              (0.35 + selectedLaser * 0.1) * pos;
                          }
                        })
                      )
                      .insert(
                        1.25,
                        new Tween(0.25, (pos, dt) => {
                          if (pos === 0) {
                            // bulletController.smallLaserContainer.visible = false;
                            bulletController.laserImpactContainer.visible = true;
                            bulletController.laserImpactContainer.position.set(
                              targetPosition.x,
                              targetPosition.y
                            );
                            bulletController.laserImpactContainer.alpha = 1;
                            bulletController.laserImpactContainer.scale.set(0);
                          } else if (pos === 1) {
                            // bulletController.laserImpactContainer.alpha = 0;
                            bulletController.laserImpactContainer.scale.set(
                              0.5
                            );
                          } else {
                            // bulletController.laserImpactContainer.alpha =
                            //   1 - pos;
                            bulletController.laserImpactContainer.scale.set(
                              pos / 2
                            );
                          }
                        })
                      )
                      .insert(
                        1.5,
                        new Tween(0.25, (pos, dt) => {
                          if (pos === 0) {
                            bulletController.smallLaserContainer.visible = false;
                            bulletController.laserImpactContainer.visible = true;

                            bulletController.laserImpactContainer.alpha = 1;
                            bulletController.laserImpactContainer.scale.set(1);
                          } else if (pos === 1) {
                            bulletController.laserImpactContainer.alpha = 0;
                            bulletController.laserImpactContainer.scale.set(0);
                          } else {
                            bulletController.laserImpactContainer.alpha =
                              1 - pos;
                            bulletController.laserImpactContainer.scale.set(
                              0.5 - pos / 2
                            );
                          }
                        })
                      )
                      .play();
                  });

                  if (audioRef.current._group["BOS-006"]) {
                    setTimeout(() => {
                      audioRef.current._group["BOS-006"]._list[0].play(
                        "hitTarget"
                      );
                    }, 1500);
                  }

                  await character.controller.routineWait(1.5);
                },
                finalAttack: async (self, allTargetPlayer) => {
                  // console.log("final attack from boss");

                  new Tweener(app.ticker)
                    .insert(
                      1.16,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.bigLaserBodyContainer.scale.set(
                            10,
                            0.5
                          );
                          emitterController.bigLaserStartContainer.scale.set(
                            0.5
                          );
                        }
                        if (pos === 0) {
                          emitterController.bigLaserBodyContainer.visible = true;
                          emitterController.bigLaserStartContainer.visible = true;
                        } else {
                          emitterController.bigLaserBodyContainer.scale.set(
                            10,
                            // 0.5 * pos
                            pos
                          );
                          emitterController.bigLaserStartContainer.scale.set(
                            0.5,
                            // 0.5 * pos
                            pos
                          );
                        }
                      })
                    )
                    .insert(
                      1.56,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.bigLaserBodyContainer.visible = false;
                          emitterController.bigLaserStartContainer.visible = false;
                          emitterController.bigLaserBodyContainer.scale.set(
                            10,
                            0
                          );
                          emitterController.bigLaserStartContainer.scale.set(
                            0.5,
                            0
                          );
                        } else {
                          emitterController.bigLaserBodyContainer.scale.set(
                            10,
                            // -0.5 * pos + 0.5
                            1 - pos
                          );
                          emitterController.bigLaserStartContainer.scale.set(
                            0.5,
                            // -0.5 * pos + 0.5
                            1 - pos
                          );
                        }
                      })
                    )

                    .play();
                  if (audioRef.current._group["BOS-006"]) {
                    audioRef.current._group["BOS-006"]._list[0].play("charge");
                    setTimeout(() => {
                      audioRef.current._group["BOS-006"]._list[0].play(
                        "hitTarget"
                      );
                      audioRef.current._group["BOS-006"]._list[0].play(
                        "finalAttackExtra"
                      );
                    }, 1166);
                  }

                  await character.controller.routineWait(1.3);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["BOS-006"]) {
                      audioRef.current._group["BOS-006"]._list[0].play("dead");
                    }
                  }
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                  controller.containerParticle?.destroy();
                },
                talk: () => {
                  if (audioRef.current._group["BOS-006"])
                    audioRef.current._group["BOS-006"]._list[0].play("talk");
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_DMITRI" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
