import * as PIXI from "pixi.js";

import curse_1 from "./curse_1.png";
import curse_2 from "./curse_2.png";
import curse_3 from "./curse_3.png";

const KosmoMinionParticle = {
  load: ({ app, containerParticle }) => {
    return new Promise(async (resolve, reject) => {
      let curseTexture;
      let rngTexture = Math.random() * 3;

      if (rngTexture < 1) {
        curseTexture = PIXI.Texture.from(curse_1);
      } else if (rngTexture >= 1 && rngTexture < 2) {
        curseTexture = PIXI.Texture.from(curse_2);
      } else {
        curseTexture = PIXI.Texture.from(curse_3);
      }

      const curseContainer = new PIXI.Sprite(curseTexture);
      curseContainer.anchor.set(0.5);
      containerParticle.addChild(curseContainer);
      resolve({ curseContainer });
      app.loader.onError.add((e) => {
        reject(e);
      });
    });
  },
};

export default KosmoMinionParticle;
