/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import AppAudio from "Classes/AppAudio";
import UpdateRatio from "Classes/UpdateRatio";

import { take } from "lodash";

import useSoundButton from "Hooks/useSoundButton";
import isEmpty from "lodash/isEmpty";

import soundOneShot from "Game/Assets/Audio/go_sound_oneshot.mp3";
import galaxySoundOneShot from "Game/Assets/Audio/galaxy_go_sound_oneshot.mp3";

const AppContext = createContext();

function useWindowSize(scene) {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    maxWidth: 0,
    maxHeight: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height:
          window.innerHeight <= window.innerWidth * 0.5625
            ? window.innerHeight
            : window.innerWidth * 0.5625,
        maxWidth: window.innerHeight * 1.7778,
        maxHeight: window.innerWidth * 0.5625,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [scene]);

  return windowSize;
}
const themeBGMSoundSpriteConfig = {
  dolfindam: {
    lobby: [0, 113000],
    gameplay: [120000, 55000],
    boss: [180000, 101000],
    win: [300000, 15000],
    lose: [360000, 15000],
  },
  flipperborea: {
    lobby: [0, 113000],
    gameplay: [120000, 55000],
    boss: [180000, 101000],
    win: [300000, 15000],
    lose: [360000, 15000],
  },
  // flipperborea: {
  //   lobby: [0, 34000],
  //   gameplay: [60000, 78000],
  //   boss: [180000, 101000],
  //   win: [300000, 15000],
  //   lose: [360000, 15000],
  // },
  tsurunotani: {
    lobby: [0, 113000],
    gameplay: [120000, 55000],
    boss: [180000, 101000],
    win: [300000, 15000],
    lose: [360000, 15000],
  },
  mayurabad: {
    lobby: [0, 113000],
    gameplay: [120000, 55000],
    boss: [180000, 101000],
    win: [300000, 15000],
    lose: [360000, 15000],
  },
  yosegoni: {
    lobby: [0, 113000],
    gameplay: [120000, 55000],
    boss: [180000, 101000],
    win: [300000, 15000],
    lose: [360000, 15000],
  },
  "avesta-12b": {
    lobby: [0, 16184],
    gameplay: [20000, 49000],
    boss: [75000, 23306],
    win: [120000, 48000],
    // win: [120000, 15000],
    lose: [180000, 33700],
    // lose: [180000, 15000],
  },
  "nova-centauri": {
    lobby: [0, 16184],
    gameplay: [20000, 49000],
    boss: [75000, 23306],
    win: [120000, 48000],
    // win: [120000, 15000],
    lose: [180000, 33700],
    // lose: [180000, 15000],
  },
  loventures: {
    lobby: [156000, 516000],
    gameplay: [156000, 516000],
    boss: [0, 155000],
    win: [300000, 15000],
    lose: [360000, 15000],
  },
};

const characterSoundSpriteConfig = {
  "VND-001": {
    attack: [0, 600],
    hitTarget: [1000, 500],
  },
  "VND-002": {
    attack: [0, 800],
  },
  "VND-003": {
    attack: [0, 300],
    hitTarget: [300, 400],
  },
  "VND-004": {
    charge: [0, 900],
    attack: [1000, 1000],
    hitTarget: [2000, 2000],
  },
  "VND-005": {
    attack: [0, 500],
    hitTarget: [1000, 2000],
  },
  "VND-006": {
    splash: [0, 1000],
    attack: [1000, 1250],
    hit: [3000, 860],
    hitTarget: [4000, 1000],
  },
  "VND-007": {
    cast: [0, 3000],
    beam: [3000, 4000],
  },
  "VND-008": {
    attack: [0, 1000],
    hitTarget: [1000, 700],
  },
  "VND-010": {
    pour: [0, 1000],
    sipping: [1000, 800],
    attack: [2000, 1000],
    hitTarget: [3000, 2000],
    dead: [5000, 1000],
    afterDead: [6000, 1300],
    geta1: [8000, 500],
    geta2: [8500, 500],
    geta3: [9000, 500],
    geta4: [9500, 500],
    fireworkLaunch: [10000, 1000],
    fireworkExplode: [11000, 2000],
  },
  "VND-011": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "VND-012": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "VND-013": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "VND-014": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "VND-015": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "VND-016": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "VND-017": {
    calculate: [0, 1000],
    stretch: [1000, 1000],
    fired: [2000, 1000],
    release: [3000, 2000],
    hitTarget: [5000, 1000],
    helmetPop: [6000, 1000],
    connectHelmet: [7000, 2000],
    takeOffCloth: [9000, 1000],
    puffUp: [10000, 1500],
    explode: [12000, 1000],
    danger: [13000, 3000],
  },
  "ENM-001": {
    attack: [0, 300],
    hitTarget: [1000, 370],
    dead: [2000, 500],
  },
  "ENM-002": {
    attack: [0, 300],
    hitTarget: [1000, 370],
    dead: [2000, 500],
  },
  "ENM-003": {
    attack: [0, 300],
    hitTarget: [1000, 370],
    dead: [2000, 500],
  },
  "ENM-004": {
    attack: [0, 150],
    hitTarget: [500, 500],
    roar: [1000, 500],
    dead: [2000, 650],
  },
  "ENM-005": {
    attack: [0, 150],
    hitTarget: [500, 500],
    roar: [1000, 500],
    dead: [2000, 650],
  },
  "ENM-006": {
    attack: [0, 150],
    hitTarget: [500, 500],
    roar: [1000, 500],
    dead: [2000, 650],
  },
  "ENM-007": {
    attack: [0, 1000],
    hitTarget: [1000, 700],
    dead: [2000, 800],
  },
  "ENM-008": {
    attack: [0, 1000],
    hitTarget: [1000, 700],
    dead: [2000, 800],
  },
  "ENM-009": {
    attack: [0, 1000],
    hitTarget: [1000, 700],
    dead: [2000, 800],
  },
  "ENM-010": {
    attack: [0, 1000],
    hitTarget: [1000, 1000],
    dead: [2000, 2000],
  },
  "ENM-011": {
    attack: [0, 1000],
    hitTarget: [1000, 1000],
    dead: [2000, 2000],
  },
  "ENM-012": {
    attack: [0, 1000],
    hitTarget: [1000, 1000],
    dead: [2000, 2000],
  },
  "ENM-013": {
    attack: [0, 1000],
    hitTargetNormal: [1000, 1000],
    hitTargetBone: [2000, 1000],
    hitTargetClub: [3000, 1000],
  },
  "ENM-014": {
    attack: [0, 1000],
    hitTargetNormal: [1000, 1000],
    hitTargetBone: [2000, 1000],
    hitTargetClub: [3000, 1000],
  },
  "ENM-015": {
    attack: [0, 1000],
    hitTargetNormal: [1000, 1000],
    hitTargetBone: [2000, 1000],
    hitTargetClub: [3000, 1000],
  },
  "ENM-016": {
    attack: [0, 500],
    hitTarget: [500, 500],
    dead: [1000, 1000],
  },
  "ENM-017": {
    attack: [0, 500],
    hitTarget: [500, 500],
    dead: [1000, 1000],
  },
  "ENM-018": {
    attack: [0, 500],
    hitTarget: [500, 500],
    dead: [1000, 1000],
  },
  "ENM-019": {
    attack: [0, 500],
    hitTarget: [1000, 1000],
    dead: [2000, 1400],
  },
  "ENM-020": {
    attack: [0, 500],
    hitTarget: [1000, 1000],
    dead: [2000, 1400],
  },
  "ENM-021": {
    attack: [0, 500],
    hitTarget: [1000, 1000],
    dead: [2000, 1400],
  },
  "BOS-001": {
    portal: [0, 700],
    sword: [1000, 800],
    stomp: [2000, 560],
    dead: [3000, 1850],
    roar: [5000, 2000],
    talk: [7000, 1600],
  },
  "BOS-002": {
    mammothRoar: [0, 2000],
    penguinRoar: [2000, 650],
    iceHit: [3000, 2250],
    iceCrack: [6000, 1600],
    stomp: [8000, 600],
    arrow: [9000, 200],
    arrowHit: [10000, 400],
    switch: [11000, 500],
    mechanic: [12000, 1000],
    mammothDead: [13000, 2000],
    penguinDead: [15000, 650],
    penguinTalk: [17000, 3000],
  },
  "BOS-003": {
    talk: [0, 3500],
    charge: [4000, 2000],
    attack: [6000, 1200],
    hitTarget: [8000, 2600],
    hitTargetMusic: [11000, 3300],
    finalCharge: [15000, 4000],
    finalHitTarget: [19000, 2200],
    dead: [22000, 3000],
  },
  "BOS-004": {
    attack: [0, 600],
    hitTarget: [1000, 1000],
    whip: [2000, 1000],
    dead: [3000, 2000],
    finalFire: [5000, 2500],
    talk: [8000, 5000],
  },
  "BOS-005": {
    talk: [0, 3000],
    command: [3000, 2500],
    dead: [6000, 2000],
    running: [8000, 2000],
    finalRunning: [10000, 5000],
    hitTarget: [15000, 1000],
  },
  "BOS-006": {
    talk: [0, 4000],
    attack: [4000, 2000],
    charge: [6000, 1200],
    finalAttackExtra: [8000, 1000],
    hitTarget: [9000, 1000],
    dead: [10000, 1000],
  },
  "BOS-007": {
    attack: [0, 1000],
    finalAttack: [1000, 5000],
    dead: [6000, 6000],
    talk: [12000, 7000],
  },
};

const AppProvider = ({ children }) => {
  const gameRef = useRef({});
  const audioRef = useRef(new AppAudio());
  const updateRatioRef = useRef(new UpdateRatio());
  const size = useWindowSize();
  const [width, setWidth] = useState(size.width);
  const [height, setHeight] = useState(size.height);

  const [isLoadingStage, setIsLoadingStage] = useState(false);
  const [isReadyToPlay, setIsReadyToPlay] = useState(false);
  const [isShowChallenge, setIsShowChallenge] = useState(false);

  const [maxWidth, setMaxWidth] = useState(size.maxWidth);
  const [maxHeight, setMaxHeight] = useState(size.maxHeight);

  const [endusers, setEndusers] = useState([]);
  const [newUserID, setNewUserID] = useState(null);
  const [removeUserID, setRemoveUserID] = useState(null);

  // const [characterVariant, setCharacterVariant] = useState([]);

  const [currentGameState, setCurrentGameState] = useState(null);
  const [animationState, setAnimationState] = useState(null);

  const [top3Player, setTop3Player] = useState([]);

  const [currentGameData, setCurrentGameData] = useState(null);
  const [gameContextState, setGameContextState] = useState(null);

  // const [topEndUser, setTopEndUser] = useState([]);
  // const [oldTopEndUser, setOldTopEndUser] = useState([]);

  const [isShowExplanation, setIsShowExplanation] = useState(false);
  const [isBossSay, setIsBossSay] = useState(false);
  const [finalBossSay, setFinalBossSay] = useState(false);
  const [isShowBoss, setIsShowBoss] = useState(false);
  const [isCharacterReady, setIsCharacterReady] = useState(false);

  const [themeAsset, setThemeAsset] = useState([]);
  const [themeEnemies, setThemeEnemies] = useState([]);
  const [themeName, setThemeName] = useState("");
  const [themeAudio, setThemeAudio] = useState();
  const [castleIcon, setCastleIcon] = useState("");
  const [bossIcon, setBossIcon] = useState("");
  const [isLoadedAllCharacter, setIsLoadedAllCharacter] = useState(false);
  // const [allCharacterData, setAllCharacterData] = useState([]);
  const [soundVolume, setSoundVolume] = useState(1);
  const [audioGroupList, setAudioGroupList] = useState([]); //useState(["ui"]);
  const findBossOut = (80 / 100) * currentGameData?.totalQuestion;

  // console.log("audioRef", audioRef);

  // const SFX = useSoundButton(audioRef, soundOneShot, false);
  const [isPlayingSound, setIsPlayingSound] = useState(false);
  const [isBGMReady, setIsBGMReady] = useState(false);

  const playSFX = useCallback(
    (name) => {
      // if (SFX.isReady) {
      audioRef.current._group["ui"]._list[0].play(name);
      // }
    },
    [] //[SFX.isReady]
  );

  //  const soundButton = useHowler(
  //    ["http://localhost:3000/audio/button_sprite.mp3"],
  //    {
  //      volume: 1.0,
  //      sprite: {
  //        major: [0, 500],
  //        toggle: [1000, 500],
  //        minor: [2000, 500],
  //        tick: [3000, 500],
  //      },
  //    }
  //  );
  const setAllAudioVolume = useCallback(
    (volumeConfig) => {
      if (volumeConfig)
        audioGroupList.forEach((groupKey) => {
          audioRef.current._group[groupKey]._list[0].volume(volumeConfig);
        });
      if (volumeConfig === 0) {
        audioGroupList.forEach((groupKey) => {
          audioRef.current._group[groupKey]._list[0].volume(volumeConfig);
        });
      }
    },
    [audioGroupList]
  );
  useEffect(() => {
    if (audioRef?.current) {
      // console.log("soundVolumn", soundVolume);
      // console.log(audioRef.current);
      setAllAudioVolume(soundVolume);
    }
  }, [setAllAudioVolume, soundVolume]);
  useEffect(() => {
    if (isEmpty(endusers)) {
      setIsCharacterReady(false);
    }
  }, [endusers]);

  useEffect(() => {
    if (
      isBGMReady &&
      currentGameState?.showLobby &&
      !isPlayingSound &&
      themeName &&
      themeAudio &&
      audioRef.current._group[themeName]
    ) {
      // console.log("playsound");
      setIsPlayingSound(true);
      // console.log("AudioBGM.howlRef.current", AudioBGM.howlRef.current);
      // AudioBGM.howlRef.current.play("lobby");
      audioRef.current._group[themeName]._list[0].stop();
      audioRef.current._group[themeName]._list[0].play("lobby");
      // audioRef.current._group["bgm"]._list[0].stop();
      // audioRef.current._group["bgm"]._list[0].play("lobby");
    } else if (currentGameState?.finalLeaderboard) {
      if (currentGameData?.isSessionWin) {
        if (!audioRef.current._group[themeName]._list[0].playing()) {
          audioRef.current._group[themeName]._list[0].stop();
          audioRef.current._group[themeName]._list[0].play("win");
        }
        // audioRef.current._group["bgm"]._list[0].play("win");
      } else {
        if (!audioRef.current._group[themeName]._list[0].playing()) {
          audioRef.current._group[themeName]._list[0].stop();
          audioRef.current._group[themeName]._list[0].play("lose");
        }
        // audioRef.current._group["bgm"]._list[0].play("lose");
      }
    }
  }, [
    currentGameData,
    currentGameState,
    isPlayingSound,
    themeName,
    themeAudio,
    isBGMReady,
  ]);
  // useEffect(() => {
  //   if (soundButtonSFX.isReady) console.log("soundButtonSFX", soundButtonSFX);
  // }, [soundButtonSFX]);
  useEffect(() => {
    if (currentGameData) {
      if (currentGameData?.totalQuestion === 1) {
        gameRef.current.isOneQuestionOnly = true;
      } else {
        if (currentGameData?.questionIndex === Math.ceil(findBossOut)) {
          setIsShowBoss(true);
        }
      }
    }
  }, [currentGameData, isShowBoss, findBossOut]);

  useEffect(() => {
    if (isReadyToPlay && currentGameData?.questionIndex === 1) {
      if (
        currentGameData?.questionIndex >=
          Math.ceil(0.8 * currentGameData?.totalQuestion) ||
        currentGameData?.totalQuestion === 1
      ) {
        audioRef.current._group[themeName]._list[0].stop();
        audioRef.current._group[themeName]._list[0].play("boss");
        // audioRef.current._group["bgm"]._list[0].stop();
        // audioRef.current._group["bgm"]._list[0].play("boss");
      } else {
        // console.log("play gameplay sound");
        audioRef.current._group[themeName]._list[0].stop();
        audioRef.current._group[themeName]._list[0].play("gameplay");
        // audioRef.current._group["bgm"]._list[0].stop();
        // audioRef.current._group["bgm"]._list[0].play("gameplay");
      }
    }
  }, [
    currentGameData?.questionIndex,
    currentGameData?.totalQuestion,
    isReadyToPlay,
    themeName,
  ]);

  const loadSound = useCallback(async (audioRef, options, group) => {
    setAudioGroupList((prev) => {
      let newData = [...prev];
      if (!newData.includes(group)) {
        newData.push(group);
      }
      return newData;
    });
    await audioRef.current.loadAsync(options, group).then(() => {
      // console.log("audioRef.current", audioRef.current);
    });
  }, []);

  const loadBGMSound = useCallback(
    async (themeAudio) => {
      let src = themeAudio.mp3;
      let options = {
        src,
        loop: true,
        sprite: themeBGMSoundSpriteConfig[themeName],
      };
      await loadSound(audioRef, options, themeName);
      setIsBGMReady(true);
    },
    [loadSound, themeName]
  );

  const loadSoundOneshot = useCallback(
    async (themeName) => {
      let src;
      switch (themeName) {
        case "nova-centauri":
        case "avesta-12b":
          src = galaxySoundOneShot;
          break;
        default:
          src = soundOneShot;
          break;
      }

      let options = {
        src,
        loop: false,
        sprite: {
          major: [0, 500],
          toggle: [1000, 500],
          minor: [2000, 500],
          tick: [3000, 500],
          victory: [4000, 8000],
          defeat: [12000, 5000],
          explanation: [18000, 2000],
        },
      };
      await loadSound(audioRef, options, "ui");
    },
    [loadSound]
  );
  useEffect(() => {
    if (themeName && themeAudio) {
      loadBGMSound(themeAudio);
      loadSoundOneshot(themeName);
    }
  }, [loadBGMSound, themeName, themeAudio, loadSoundOneshot]);
  useEffect(() => {
    if (themeEnemies) {
      let promise = themeEnemies[0]?.minion.map(async (enemy) => {
        let src = enemy.audio.mp3; // sfx_bandit;
        let options = {
          src,
          loop: false,
          sprite: characterSoundSpriteConfig[enemy.characterCode],
        };
        await loadSound(audioRef, options, enemy.characterCode);
      });
      if (promise)
        Promise.all(promise).then(async () => {
          let src = themeEnemies[0]?.boss[0]?.audio.mp3; //sfx_gustav;
          let bossID = themeEnemies[0]?.boss[0]?.characterCode;
          let options = {
            src,
            loop: false,
            sprite: characterSoundSpriteConfig[bossID],
          };
          await loadSound(audioRef, options, bossID);
        });
    }
  }, [loadSound, themeEnemies]);

  const updateEndUsers = useCallback(
    (userID, content) => {
      setEndusers((prev) => {
        const newData = [...prev];

        const index = newData.findIndex((member) => {
          return member.userID === userID;
        });

        if (index >= 0) {
          newData[index] = {
            userID: userID,
            characterID: content?.characterID,
            // "VND-008",
            characterPath: content?.characterSpine,
            // "https://dh8bdvjvmxojs.cloudfront.net/go/character/VND-008/VND-008.json",
            characterAudio: content?.characterAudio,
            name: content?.name,
            registedTime: Date.now(),
          };
        } else {
          newData.push({
            userID: userID,
            characterID: content?.characterID,
            // "VND-008",
            characterPath: content?.characterSpine,
            // "https://dh8bdvjvmxojs.cloudfront.net/go/character/VND-008/VND-008.json",
            characterAudio: content?.characterAudio,
            name: content?.name,
            registedTime: Date.now(),
          });
        }

        return newData;
      });

      setNewUserID(userID);
    },
    [] //[loadSound]
  );
  // useEffect(() => {
  //   if (characterVariant) {
  //     console.log("characterVariant", characterVariant);
  //   }
  // }, [characterVariant]);

  const removeEndUsers = useCallback((userID) => {
    setEndusers((prev) => {
      const newData = prev.filter((item) => {
        return item.userID !== userID;
      });
      return newData;
    });
    setNewUserID(null);
    setRemoveUserID(userID);
  }, []);

  const addCharacterToLobby = useCallback(
    async ({ userID, userName, characterID, characterPath }) => {
      if (!isReadyToPlay) {
        const timeStamp = Date.now();
        // console.log("timeStamp", timeStamp);
        setIsCharacterReady(false);
        gameRef.current
          .addCharacterToLobby({
            characterID,
            characterPath,
            userID,
            userName,
            timeStamp,
          })
          .then(async () => {
            setIsCharacterReady(true);
            return await gameRef.current.loopWalking(userID, timeStamp);
          });
        gameRef.current.addCharacterToGameplay({
          characterID,
          characterPath,
          userID,
          userName,
        });
      }
    },
    [isReadyToPlay]
  );

  const removeCharacterFromLobby = useCallback(
    (userID) => {
      gameRef.current.removeCharacterFromLobby(userID);
      if (!isReadyToPlay && !isLoadingStage) {
        // console.log("remove character");
        gameRef.current.removeCharacterFromGameplay(userID);
      }
    },
    [isLoadingStage, isReadyToPlay]
  );

  // const updateCharacterToGameplay = useCallback(
  //   async ({ characterID, characterPath, userID, userName }) => {
  //     console.log("update character from appcontext");
  //     return await gameRef.current.updateCharacterToGameplay({
  //       characterID,
  //       characterPath,
  //       userID,
  //       userName,
  //     });
  //   },
  //   []
  // );

  const sessionStart = useCallback(
    async (topEndUser) => {
      setIsLoadingStage(true);
      gameRef.current.resetLobby();
      // const starterPlayer = [...endusers];
      // starterPlayer.length = 5;
      // console.log("starterPlayer", starterPlayer);
      // const characterData = starterPlayer.map((character) => {
      //   return {
      //     id: character.characterID, //"VND-001",
      //     path: character.characterPath,
      //     userID: character.userID,
      //     name: character.name,
      //   };
      // });
      const characterData = topEndUser.map((character) => {
        return {
          id: character.characterID,
          path: character.characterSpine,
          userID: character.userID,
          name: character.name,
        };
      });

      // console.log(characterData);
      // let enemiesData = [
      //   {
      //     id: "ENM-001",
      //     path:
      //       "https://dh8bdvjvmxojs.cloudfront.net/go/character/BANDITS/Bandits.json",
      //   },
      //   {
      //     id: "ENM-002",
      //     path:
      //       "https://dh8bdvjvmxojs.cloudfront.net/go/character/BANDITS/Bandits.json",
      //   },
      //   {
      //     id: "ENM-003",
      //     path:
      //       "https://dh8bdvjvmxojs.cloudfront.net/go/character/BANDITS/Bandits.json",
      //   },
      // ];
      let enemiesData = themeEnemies[0]?.minion.map((enemy) => {
        return {
          id: enemy.characterCode,
          path: enemy.spine,
        };
      });
      let bossID = themeEnemies[0]?.boss[0]?.characterCode;
      let bossPath = themeEnemies[0]?.boss[0]?.spine;
      for (let index = 0; index < 6; index++) {
        enemiesData.push(enemiesData[Math.floor(Math.random() * 3)]);
      }
      // console.log("enemiesData", enemiesData);
      gameRef.current.resetLobby();
      return await gameRef.current
        .initialGamaplayScenario({
          levelName: themeName,
          assets: themeAsset,
          characterData: characterData.filter((item) => item),
          enemiesData: enemiesData,
          bossID: bossID,
          bossPath: bossPath,
        })
        .then(() => {
          if (isEmpty(endusers)) {
            window.location.reload();
          } else {
            setIsReadyToPlay(true);
            setTimeout(async () => {
              return await gameRef.current.gameplay.startScenario().then(() => {
                setTimeout(() => {
                  setIsBossSay(true);
                }, 3500);
                setTimeout(() => {
                  setIsBossSay(false);
                }, 5500);
                setTimeout(() => {
                  setIsShowChallenge(true);
                }, 15000);
              });
            }, 1500);
          }
        });
    },
    [endusers, themeAsset, themeEnemies, themeName]
  );

  const switchPosition = useCallback(async (topEndUser) => {
    return await gameRef.current.gameplay.switchPosition(topEndUser);
  }, []);

  const spawnEnemy = useCallback(async () => {
    return await gameRef.current.gameplay.spawnEnemy();
  }, []);
  const checkGameResult = useCallback(async (phaseData) => {
    if (phaseData?.isSessionWin) {
      return await gameRef.current.victory();
    } else {
      return await gameRef.current.defeat();
    }
  }, []);

  const scenarioAttack = useCallback(
    (oldTopEndUser) => {
      console.log("scenarioAttack in appcontext");
      if (isShowBoss || currentGameData?.totalQuestion === 1) {
        gameRef.current.gameplay.scenarioAttackBoss(oldTopEndUser).then(() => {
          // console.log("Attack boss complete!!!");
          setIsShowExplanation(true);
        });
      } else {
        gameRef.current.gameplay.scenarioAttack(oldTopEndUser).then(() => {
          // console.log("scenarioAttack complete!!!");
          setIsShowExplanation(true);
        });
      }
    },
    [currentGameData, isShowBoss]
  );

  const stopAudioOnExplanation = useCallback(() => {
    audioRef.current._group[themeName]._list[0].stop();
    audioRef.current._group["ui"]._list[0].play("explanation");
  }, [themeName]);

  useEffect(() => {
    if (isShowExplanation) {
      stopAudioOnExplanation();
    }
  }, [isShowExplanation, stopAudioOnExplanation]);

  const onPhaseUpdate = useCallback(
    (phaseData) => {
      console.log("onPhaseUpdate work");
      switch (phaseData.phase) {
        case "lobby":
          if (phaseData.endUsers) {
            phaseData.endUsers.forEach((player) => {
              addCharacterToLobby({
                userID: player.userID,
                userName: player?.name,
                characterID: player?.characterID,
                characterPath: player.characterSpine,
              });
            });
          }
          break;
        case "standby":
          if (phaseData.phase === "standby" && phaseData.questionIndex !== 1) {
            if (
              phaseData.questionIndex >=
                Math.ceil(0.8 * phaseData.totalQuestion) ||
              phaseData.totalQuestion === 1
            ) {
              audioRef.current._group[themeName]._list[0].stop();
              audioRef.current._group[themeName]._list[0].play("boss");
              // audioRef.current._group["bgm"]._list[0].stop();
              // audioRef.current._group["bgm"]._list[0].play("boss");
            } else {
              // console.log("play gameplay sound");
              audioRef.current._group[themeName]._list[0].stop();
              audioRef.current._group[themeName]._list[0].play("gameplay");
              // audioRef.current._group["bgm"]._list[0].stop();
              // audioRef.current._group["bgm"]._list[0].play("gameplay");
            }
            // setTopEndUser(phaseData.topEndUser);
            if (
              phaseData.questionIndex < Math.ceil(0.8 * phaseData.totalQuestion)
            ) {
              spawnEnemy();
              switchPosition(phaseData.topEndUser);
            } else if (
              phaseData.questionIndex ===
              Math.ceil(0.8 * phaseData.totalQuestion)
            ) {
              setTimeout(() => {
                switchPosition(phaseData.topEndUser);
              }, 14000);
            } else {
              switchPosition(phaseData.topEndUser);
            }
          }
          setIsShowExplanation(false);
          break;
        case "micro-contents":
          break;
        case "questioning":
          break;
        case "answering":
          break;
        case "explanation":
          // console.log("phaseData", phaseData);

          // let incorrectPlayerLength = phaseData?.oldTopEndUser?.filter(
          //   (user) => user.correction !== "correct"
          // ).length;
          // let timesupPlayerLength = phaseData?.oldTopEndUser?.filter(
          //   (user) => user.correction === "timesup"
          // ).length;
          // let topPlayerLength = phaseData?.oldTopEndUser?.length;
          // setTimeout(() => {
          //   // audioRef.current._group["bgm"]._list[0].stop();
          //   audioRef.current._group[themeName]._list[0].stop();
          //   audioRef.current._group["ui"]._list[0].play("toggle");
          // }, (topPlayerLength * 0.25 + incorrectPlayerLength * 0.75 - timesupPlayerLength * 0.5) * 1000 + 3000);
          scenarioAttack(phaseData?.oldTopEndUser);
          // setOldTopEndUser(() => {
          //   return phaseData?.oldTopEndUser;
          // });

          break;
        case "end":
          setIsShowExplanation(false);
          setTop3Player(phaseData.topEndUser);
          checkGameResult(phaseData);
          break;
        default:
          throw new Error(
            "Unknown phase has been occuired. This should not be happened!"
          );
      }
    },
    [
      checkGameResult,
      addCharacterToLobby,
      themeName,
      spawnEnemy,
      switchPosition,
      scenarioAttack,
    ]
  );

  const resetGameSession = useCallback(async () => {
    setIsLoadingStage(false);
    setIsReadyToPlay(false);
    setIsShowChallenge(false);
    setNewUserID(null);
    setRemoveUserID(null);
    setTop3Player([]);
    // setTopEndUser([]);
    // setOldTopEndUser([]);
    setIsBossSay(false);
    setFinalBossSay(false);
    setIsShowBoss(false);
    setIsPlayingSound(false);
    await gameRef.current.reset();
    await gameRef.current.initialLobbyScenario();
  }, []);

  useEffect(() => {
    if (endusers) {
      // console.log("endusers", endusers);
      const newData = endusers.sort((a, b) => {
        if (a.registedTime < b.registedTime) {
          return -1;
        }
        if (a.registedTime > b.registedTime) {
          return 1;
        }
        return 0;
      });

      if (newUserID) {
        // console.log("newUserID", newUserID);
        addCharacterToLobby({
          userID: newData[newData.length - 1]?.userID,
          userName: newData[newData.length - 1]?.name,
          characterID: newData[newData.length - 1]?.characterID,
          characterPath: newData[newData.length - 1]?.characterPath || "",
        });
        setNewUserID(null);
      }
      if (removeUserID) {
        removeCharacterFromLobby(removeUserID);
        setRemoveUserID(null);
      }
    }
  }, [
    addCharacterToLobby,
    endusers,
    newUserID,
    removeCharacterFromLobby,
    removeUserID,
  ]);

  useEffect(() => {
    if (size) {
      setWidth(size.width);
      setHeight(size.height);
      setMaxWidth(size.maxWidth);
      setMaxHeight(size.maxHeight);
    }
  }, [size]);

  useEffect(() => {
    gameRef.current.initialLobbyScenario();
    // gameRef.current.testLoadNewTheme();
  }, []);

  const finalLeaderboard = useCallback(async () => {
    const findTop3 = take(top3Player, 3);
    const isWin = currentGameData?.isSessionWin;
    return await gameRef.current.leaderboard(findTop3, isWin);
  }, [top3Player, currentGameData]);

  const playBossScene = useCallback(async () => {
    return await gameRef.current.bossWalkInStage().then(() => {
      setTimeout(() => {
        setFinalBossSay(true);
      }, 3500);
      setTimeout(() => {
        setFinalBossSay(false);
      }, 5500);
    });
  }, []);

  // useEffect(() => {
  //   if (currentGameData) {
  //     console.log("currentGameData", currentGameData);
  //   }
  // }, [currentGameData]);

  useEffect(() => {
    if (currentGameState) {
      if (
        currentGameData?.totalQuestion !== 1 &&
        currentGameData?.questionIndex === Math.ceil(findBossOut) &&
        !gameRef.current.bossWalked
      ) {
        playBossScene();
      }
      if (currentGameState.finalLeaderboard) {
        finalLeaderboard();
      }
    }
  }, [
    currentGameState,
    finalLeaderboard,
    playBossScene,
    isShowBoss,
    findBossOut,
    currentGameData,
  ]);

  const checkTop5 = useCallback(() => {
    gameRef.current.gameplay.endUserAnswer(
      gameContextState?.userAnswer[gameContextState?.userAnswer.length - 1]
        ?.userID
    );
  }, [gameContextState?.userAnswer]);

  useEffect(() => {
    if (gameContextState?.userAnswer) {
      // console.log("gameContextState.userAnswer", gameContextState?.userAnswer);
      checkTop5();
    }
  }, [checkTop5, gameContextState?.userAnswer]);

  // useEffect(() => {
  //   if (oldTopEndUser) {
  //     console.log("oldTopEndUser", oldTopEndUser);
  //     scenarioAttack(oldTopEndUser);
  //   }
  // }, [oldTopEndUser, scenarioAttack]);

  const preloadCharacter = useCallback(
    async (characterDataList) => {
      // TODO: use this after get data from websocket

      await gameRef.current.preloadCharacter(characterDataList);
      // console.log("load complete");
      setIsLoadedAllCharacter(true);

      characterDataList.forEach(async (character) => {
        let src = character?.audio[0].mp3;
        let options = {
          src,
          loop: false,
          sprite: characterSoundSpriteConfig[character?.characterCode],
        };
        await loadSound(audioRef, options, character?.characterCode);
      });
    },
    [loadSound]
  );

  // useEffect(() => {
  //   if (themeAsset && themeEnemies) {
  //     console.log("themeAsset", themeAsset);
  //     console.log("themeEnemies", themeEnemies);
  //   }
  // }, [themeAsset, themeEnemies]);

  return (
    <AppContext.Provider
      value={{
        audioRef,
        updateRatioRef,
        width,
        height,
        // innerWidth,
        // innerHeight,
        maxWidth,
        maxHeight,
        gameRef,
        endusers,
        setEndusers,

        isReadyToPlay,

        updateEndUsers,
        removeEndUsers,
        currentGameState,
        setCurrentGameState,
        animationState,
        setAnimationState,
        sessionStart,
        currentGameData,
        setCurrentGameData,
        gameContextState,
        setGameContextState,
        isBossSay,
        finalBossSay,

        isShowChallenge,
        isShowBoss,
        setIsShowBoss,
        resetGameSession,
        playSFX,
        onPhaseUpdate,
        isCharacterReady,

        themeAsset,
        setThemeAsset,
        themeEnemies,
        setThemeEnemies,
        themeName,
        setThemeName,
        castleIcon,
        setCastleIcon,
        bossIcon,
        setBossIcon,

        soundVolume,
        setSoundVolume,
        setThemeAudio,
        isShowExplanation,
        preloadCharacter,
        isLoadedAllCharacter,
        // updateCharacterToGameplay,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
