import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";

import KosmoMinionParticle from "Game/PixiCores/Particles/KosmoMinion/KosmoMinionParticle.js";
import { Tween, Tweener } from "Classes/Tween";

const character = {
  controller: {},
  scale: 0.1,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charging", loop: false },
      { name: "ChargingLoop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Charging", loop: false },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Hit", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true }],
    lose: [{ name: "Lose", loop: false }],
  },
  skins: ["Oz", "Soz", "Voz"],
  event: {
    Attack: () => {
      //   character.controller.setFiredBulletTo(
      //     character.controller.targetPosition
      //   );
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  create: (app, audioRef, path) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          const preload = new PIXI.Loader();
          preload.add("character", path);
          preload.load(async (loader, resources) => {
            let spineData = resources["character"].spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);
              spine.skeleton.setSkinByName("Soz");
              let container = new PIXI.Container();
              let containerParticle = new PIXI.Container();

              let emitterController;

              container.addChild(spine);
              container.addChild(containerParticle);

              await KosmoMinionParticle.load({ app, containerParticle }).then(
                (particle) => {
                  emitterController = particle;
                }
              );

              const controller = {
                spine,
                container,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                infoCharacter: {
                  characterName: "Soz",
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setDisappearedParticleActive: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  containerParticle.visible = true;

                  emitterController.curseContainer.position.set(
                    targetPosition.x,
                    targetPosition.y + 40
                  );
                  emitterController.curseContainer.visible = false;
                  new Tweener(app.ticker)
                    .insert(
                      1,
                      new Tween(0.5, (pos, dt) => {
                        if (pos === 0) {
                          if (audioRef.current._group["ENM-020"]) {
                            audioRef.current._group["ENM-020"]._list[0].play(
                              "hitTarget"
                            );
                          }
                          containerParticle.visible = true;
                          emitterController.curseContainer.visible = true;
                          emitterController.curseContainer.alpha = 1;
                        }
                        if (pos === 1) {
                          emitterController.curseContainer.alpha = 1;
                          emitterController.curseContainer.visible = false;
                        } else {
                          emitterController.curseContainer.alpha = 1 - pos;
                          emitterController.curseContainer.scale.set(pos * 0.5);
                        }
                      })
                    )
                    .play();

                  if (audioRef.current._group["ENM-020"]) {
                    setTimeout(() => {
                      audioRef.current._group["ENM-020"]._list[0].play(
                        "attack"
                      );
                    }, 500);
                  }

                  await character.controller.routineWait(1);
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                  containerParticle.visible = false;
                },
                setSkin: (skinName) => {
                  if (character.skins.indexOf(skinName) < 0) {
                    return false;
                  } else {
                    const skeleton = controller.spine.skeleton;
                    skeleton.setSkin(null);
                    skeleton.setSkinByName(skinName);
                    return true;
                  }
                },
                randomSkin: () => {
                  let index = Math.floor(
                    Math.random() * character.skins.length
                  );
                  controller.setSkin(character.skins[index]);
                },
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                  if (stateName === "lose") {
                    if (audioRef.current._group["ENM-020"]) {
                      audioRef.current._group["ENM-020"]._list[0].play("dead");
                    }
                  }
                },
                destroy: () => {
                  controller.spine?.destroy();
                  controller.container?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_Soz" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default character;
