import Vector2 from "Service/Vector2";

const moveEpsilon = 0.01;

class LobbyScenarioManager {
  constructor({ app, containerLevel, containerStage, options }) {
    this._app = app;
    this._containerLevel = containerLevel;
    this._containerStage = containerStage;
    this._level = null;
    this._characterPlayer = null;
    this._characterEnemies = [];
    this._characterBoss = null;
    this._size = options?.size || 0;
    this._position = options?.position || 0;
    this._groundHeight = options?.groundHeight || 0;
    this._offsetScreen = options?.offsetScreen || 0;
    this._offsetCharacter = options?.offsetCharacter || 0;
    this._eventUpdate = [];
    this._activateEnemy = null;
    this._previousEnemy = null;
    this.isLobbyActive = false;
    this._characterPlayerList = []; // active character
    this._allUser = [];
  }
  get containerLevel() {
    return this._containerLevel;
  }
  get containerStage() {
    return this._containerStage;
  }
  get size() {
    return this._size;
  }
  set size(value) {
    this._size = value;
    this.updateSize();
  }
  get position() {
    return this._position;
  }
  set position(value) {
    this._position = value;
  }
  get offset() {
    return this._offsetScreen;
  }
  set offset(value) {
    this._offsetScreen = value;
  }
  // initialize(level, player, enemies, boss) {
  //   this.reset();
  //   this._level = level;
  //   this._characterPlayer = player;
  //   this._characterEnemies = [...enemies];
  //   this._characterBoss = boss;
  //   this.updateSize();
  // }

  initialize(player) {
    this.reset();
    this.isLobbyActive = true;
    this._characterPlayerList = [...player];
    this.updateSize();
  }

  reset() {
    if (this._level) {
      this._level.destroy();
    }
    this.isLobbyActive = false;
    this._position = 0;
    this._characterPlayer = null;
    this._characterEnemies = [];
    this._characterPlayerList = [];
    this._characterBoss = null;
  }
  updateSize() {
    if (this._level) {
      this._level.setSize(this._size);
    }
  }
  updateRatio(width, height) {
    this._containerLevel.position.set(
      this._size / 2 + this._position,
      height / 2
    );
    this._containerStage.position.set(
      this._position,
      height - this._groundHeight
    );
  }
  update(deltaTime) {
    if (this._level) {
      this._level.setPosition(this._position);
      this._level.update();
    }
    this._containerStage.position.x = -this._position;
    this._eventUpdate.forEach((event) => event(deltaTime));
  }

  getNextPosition() {
    return this._level.areaLength + this._position;
  }
  getCharacterLeftPosition(position, index) {
    return position + this._offsetScreen + index * this._offsetCharacter;
  }
  getCharacterRightPosition(position, index) {
    return (
      position + this._size - this._offsetScreen - index * this._offsetCharacter
    );
  }

  // async loopQueueCharacter(newPlayer) {
  //   this.allUser.push(newPlayer);
  //   if (this._characterPlayerList.length < 3) {
  //     this._characterPlayerList.push(newPlayer);
  //   } else {
  //     const moveOutCharacterIndex = this._characterPlayerList.findIndex(
  //       (data) => data.isMovingOut !== true
  //     );
  //     if (moveOutCharacterIndex >= 0) {
  //       await this.characterMoveOutStage(
  //         this._characterPlayerList[moveOutCharacterIndex]
  //       ).then(() => {
  //         this._characterPlayerList.splice(moveOutCharacterIndex, 1);
  //         this._characterPlayerList.push(newPlayer);
  //       });
  //     }
  //   }
  // }

  async characterMoveOutStage(player) {
    // console.log("player out", player);
    // player.isMovingOut = true;
    await this.routineMoveCharacterToPosition(player, 1000, 0, 100).then(() => {
      player.setActive(false);
      // this._characterPlayerList.splice(0, 1);ห
    });
    // const characterWalkIndex = this._allUser.findIndex((data) => {
    //   return data.isPlayerOnLobby !== true;
    // });
    // const playerWillOut = this._allUser[characterWalkIndex];
    // console.log("length lobby list when pop", this._characterPlayerList.length);
    // this.checkqueue(playerWillOut);
  }

  // async findCharacterShouldWalk() {
  //   const characterWalkIndex = this._allUser.findIndex((data) => {
  //     return data?.isMovingOut !== true;
  //   });
  //   this._characterPlayerList.push(this._allUser[characterWalkIndex]);
  //   await this.characterMoveOutStage(this._allUser[characterWalkIndex]).then(
  //     async () => {
  //       if (this._characterPlayerList.length > 12) {
  //         await this.findCharacterShouldWalk();
  //       }
  //     }
  //   );
  // }

  // async checkqueue(newPlayer) {
  //   if (this._characterPlayerList.length < 24) {
  //     newPlayer.setActive(true);
  //     newPlayer.isPlayerOnLobby = true;
  //     // this._characterPlayerList.push(newPlayer);
  //     await this.findCharacterShouldWalk();
  //   }
  // }

  // pushCharacterList(player) {
  //   const isCharacterExist = this._allUser?.find((characterData) => {
  //     return characterData.userID === player.userID;
  //   });
  //   if (!isCharacterExist) {
  //     // console.log("new add", player);
  //     console.log(this._characterPlayerList.length);
  //     this._allUser.push(player);
  //     this.checkqueue(player);
  //   }
  // }

  // async lobbyLoop(second) {
  //   await new Promise((resolve) => {
  //     const ticker = this._app.ticker;
  //     let current = 0;
  //     const onUpdate = (elapsedTime) => {
  //       const deltaTime = (1 / ticker.FPS) * ticker.speed;
  //       current += deltaTime;
  //       if (this._characterPlayerList.length > 12) {
  //         ticker.remove(onUpdate);
  //         resolve();
  //       } else {
  //       }
  //     };
  //     ticker.add(onUpdate);
  //   });
  // }

  removeCharacterFromList(playerID) {
    this._characterPlayerList = this._characterPlayerList.filter(
      (character) => {
        return character.userID !== playerID;
      }
    );
  }

  async loopWalkingCharacter(userID, timeStamp) {
    const character = this._characterPlayerList?.find((characterData) => {
      return (
        characterData.userID === userID && characterData.timeStamp === timeStamp
      );
    });
    // console.log("character loop ", character);

    const moveSpeed = 100;
    const rng = Math.random();
    if (this.isLobbyActive && character) {
      if (rng < 0.5) {
        character?.infoCharacter.characterName === "yamato"
          ? character?.playState("idle")
          : character?.playState("win");
        return await this.routineWait(Math.random() * 3).then(async () => {
          if (character) {
            return await this.loopWalkingCharacter(userID, timeStamp);
          }
        });
      } else {
        if (character) {
          return await this.routineWait(Math.random() * 3 + 3).then(
            async () => {
              if (character) {
                return await this.routineMoveCharacterToPosition(
                  character,
                  this.getCharacterLeftPosition(Math.random() * 1800 - 900, 0),
                  // character.container.position.x > 900
                  //   ? character.container.position.x - 100
                  //   : character.container.position.x < -900
                  //   ? character.container.position.x + 100
                  //   : Math.random() * 200 - 100,
                  0,
                  moveSpeed
                ).then(async () => {
                  if (character) {
                    return await this.loopWalkingCharacter(userID, timeStamp);
                  }
                });
              }
            }
          );
        }
      }
    }
  }

  async triggerWalkCharacter(userID, timeStamp) {
    const character = this._characterPlayerList.find((character) => {
      return character.userID === userID && character.timeStamp === timeStamp;
    });
    // console.log("character 1st trigger", character);
    if (character) {
      return await this.routineMoveCharacterToPosition(
        // this._characterPlayerList[this._characterPlayerList.length - 1],
        character,
        this.getCharacterLeftPosition(Math.random() * 1000 - 500, 0),
        0,
        100
      ).then(async () => {
        const isExistCharacter = this._characterPlayerList.find((character) => {
          return (
            character.userID === userID && character.timeStamp === timeStamp
          );
        });
        if (isExistCharacter) {
          return await this.loopWalkingCharacter(
            // this._characterPlayerList[this._characterPlayerList.length - 1]
            userID,
            timeStamp
          );
        }
      });
    }
  }

  // MAX_STACK = 12;

  // runQueue() {
  //   Promise.all(this._stackWalking);
  // }

  // addToStackWalking(process) {
  //   this._stackWalking.push(process);
  // }

  // addToLobbyArray(player) {
  //   this._characterPlayer.push(player);
  // }

  // async walkInLobby(player) {
  //   this.routineWait(2);
  // }

  // async walkThrough(player) {
  //   // player.isMovingOut = true;
  //   await this.routineMoveCharacterToPosition(player, 1000, 0, 100).then(() => {
  //     player.setActive(false);
  //     this._characterPlayerList.splice(0, 1);
  //   });
  //   this.routineWait(2);
  // }

  // checkQueue(player) {
  //   const isQueueWaiting = this._characterPlayer.length > this.MAX_STACK;
  //   if (isQueueWaiting) {
  //     this.addToStackWalking(this.walkThrough(player));
  //   } else {
  //     this.addToStackWalking(this.addToLobbyArray(player));
  //   }
  // }

  // pushCharacterList(player) {
  //   const isCharacterExist = this._allUser?.find((characterData) => {
  //     return characterData.userID === player.userID;
  //   });
  //   if (!isCharacterExist) {
  //     this._allUser.push(player);
  //     this.addToStackWalking(this.checkQueue(player));
  //     if (this._stackWalking.length === 0) {
  //       this.runQueue();
  //     }
  //   }
  // }

  pushCharacterList(player) {
    const isCharacterExist = this._characterPlayerList?.find(
      (characterData) => {
        return characterData.userID === player.userID;
      }
    );
    if (!isCharacterExist) {
      if (this._characterPlayerList.length >= 8) {
        this._characterPlayerList.push(player);
        this._characterPlayerList.shift();
      } else {
        this._characterPlayerList.push(player);
      }
    }
  }

  async routineWait(second) {
    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      let current = 0;
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        current += deltaTime;
        if (current > second) {
          ticker.remove(onUpdate);
          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }

  async routineMoveStageToPosition(position, moveSpeed) {
    const sign = (value) => {
      return value >= 0 ? 1 : -1;
    };
    const abs = (value) => {
      if (value < 0) return -value;
      else return value;
    };
    const moveToward = (current, target, maxDelta) => {
      if (abs(target - current) <= maxDelta) {
        return target;
      }
      return current + sign(target - current) * maxDelta;
    };

    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        const moveDelta = deltaTime * moveSpeed;
        this._position = moveToward(this._position, position, moveDelta);
        this.update();
        if (this._position === position) {
          ticker.remove(onUpdate);
          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }

  async routineMoveCharacterToPosition(character, x, y, moveSpeed) {
    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      character.playState("move");
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        let currentPosition = character.container.position;
        let magnitude = Vector2.Magnitude(
          x,
          y,
          currentPosition.x,
          currentPosition.y
        );
        if (magnitude > moveEpsilon) {
          character.spine.skeleton.scaleX = x > currentPosition.x ? 1 : -1;
          const [moveX, moveY] = Vector2.MoveToward(
            currentPosition.x,
            currentPosition.y,
            x,
            y,
            deltaTime * moveSpeed
          );
          character.container.position.set(moveX, moveY);
        } else {
          character.container.position.set(x, y);
          character.playState("idle");
          ticker.remove(onUpdate);

          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }

  async routineMoveBossToPosition(character, x, y, moveSpeed) {
    await new Promise((resolve) => {
      const ticker = this._app.ticker;
      character.playState("move");
      const onUpdate = (elapsedTime) => {
        const deltaTime = (1 / ticker.FPS) * ticker.speed;
        let currentPosition = character.container.position;
        let magnitude = Vector2.Magnitude(
          x,
          y,
          currentPosition.x,
          currentPosition.y
        );
        if (magnitude > moveEpsilon) {
          character.spine.skeleton.scaleX = x > currentPosition.x ? -1 : 1;
          const [moveX, moveY] = Vector2.MoveToward(
            currentPosition.x,
            currentPosition.y,
            x,
            y,
            deltaTime * moveSpeed
          );
          character.container.position.set(moveX, moveY);
        } else {
          character.container.position.set(x, y);
          character.playState("idle");
          ticker.remove(onUpdate);
          resolve();
        }
      };
      ticker.add(onUpdate);
    });
  }
}

export { LobbyScenarioManager };
