import * as VND001 from "Game/PixiCores/Characters/Vonder.js";
import * as VND002 from "Game/PixiCores/Characters/Oliver.js";
import * as VND003 from "Game/PixiCores/Characters/Robin.js";
import * as VND005 from "Game/PixiCores/Characters/Bjorn";
import * as VND009 from "Game/PixiCores/Characters/Wendy";
import * as VND004 from "Game/PixiCores/Characters/Celeste.js";
import * as VND006 from "Game/PixiCores/Characters/Jin";
import * as VND007 from "Game/PixiCores/Characters/Nesha";
import * as VND008 from "Game/PixiCores/Characters/Steward";
import * as VND010 from "Game/PixiCores/Characters/Yamato";
import * as VND011 from "Game/PixiCores/Characters/ChrisIX.js";
import * as VND012 from "Game/PixiCores/Characters/AlexV";
import * as VND013 from "Game/PixiCores/Characters/Heinrich";
import * as VND014 from "Game/PixiCores/Characters/LukeJr";
import * as VND015 from "Game/PixiCores/Characters/Marcus.js";
import * as VND016 from "Game/PixiCores/Characters/Pierre.js";
import * as VND017 from "Game/PixiCores/Characters/RCPeter";

import * as ENM001 from "Game/PixiCores/Characters/Benjamin.js";
import * as ENM002 from "Game/PixiCores/Characters/Bernard.js";
import * as ENM003 from "Game/PixiCores/Characters/Brandon.js";
import * as ENM004 from "Game/PixiCores/Characters/Borris.js";
import * as ENM005 from "Game/PixiCores/Characters/Kelvin.js";
import * as ENM006 from "Game/PixiCores/Characters/Robert.js";
import * as ENM007 from "Game/PixiCores/Characters/Aka.js";
import * as ENM008 from "Game/PixiCores/Characters/Aki.js";
import * as ENM009 from "Game/PixiCores/Characters/Aku.js";
import * as ENM010 from "Game/PixiCores/Characters/Babur";
import * as ENM011 from "Game/PixiCores/Characters/Mahmud";
import * as ENM012 from "Game/PixiCores/Characters/Rajesh";
import * as ENM013 from "Game/PixiCores/Characters/Zabu";
import * as ENM014 from "Game/PixiCores/Characters/Zeke";
import * as ENM015 from "Game/PixiCores/Characters/Zulu";
import * as ENM016 from "Game/PixiCores/Characters/Niv";
import * as ENM017 from "Game/PixiCores/Characters/Ziv";
import * as ENM018 from "Game/PixiCores/Characters/Div";
import * as ENM019 from "Game/PixiCores/Characters/Oz";
import * as ENM020 from "Game/PixiCores/Characters/Soz";
import * as ENM021 from "Game/PixiCores/Characters/Voz";

import * as BOS001 from "Game/PixiCores/Characters/Gustav.js";
import * as BOS002 from "Game/PixiCores/Characters/Dmitri.js";
import * as BOS003 from "Game/PixiCores/Characters/Tenko.js";
import * as BOS004 from "Game/PixiCores/Characters/Ashoka.js";
import * as BOS005 from "Game/PixiCores/Characters/Shaka.js";
import * as BOS006 from "Game/PixiCores/Characters/Xarathrustra.js";
import * as BOS007 from "Game/PixiCores/Characters/Kosmo.js";

const CharacterList = {
  "VND-001": VND001,
  "VND-002": VND002,
  "VND-003": VND003,
  "VND-004": VND004,
  "VND-005": VND005,
  "VND-006": VND006,
  "VND-007": VND007,
  "VND-008": VND008,
  "VND-009": VND009,
  "VND-010": VND010,
  "VND-011": VND011,
  "VND-012": VND012,
  "VND-013": VND013,
  "VND-014": VND014,
  "VND-015": VND015,
  "VND-016": VND016,
  "VND-017": VND017,
  "ENM-001": ENM001,
  "ENM-002": ENM002,
  "ENM-003": ENM003,
  "ENM-004": ENM004,
  "ENM-005": ENM005,
  "ENM-006": ENM006,
  "ENM-007": ENM007,
  "ENM-008": ENM008,
  "ENM-009": ENM009,
  "ENM-010": ENM010,
  "ENM-011": ENM011,
  "ENM-012": ENM012,
  "ENM-013": ENM013,
  "ENM-014": ENM014,
  "ENM-015": ENM015,
  "ENM-016": ENM016,
  "ENM-017": ENM017,
  "ENM-018": ENM018,
  "ENM-019": ENM019,
  "ENM-020": ENM020,
  "ENM-021": ENM021,
  "BOS-001": BOS001,
  "BOS-002": BOS002,
  "BOS-003": BOS003,
  "BOS-004": BOS004,
  "BOS-005": BOS005,
  "BOS-006": BOS006,
  "BOS-007": BOS007,
};
export default CharacterList;
