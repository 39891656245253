import * as PIXI from "pixi.js";
import PIXISpine from "Plugins/pixi-spine";
import NeshaParticle from "Game/PixiCores/Particles/Nesha/NeshaParticle";
import { Tween, Tweener } from "Classes/Tween";
import * as Sentry from "@sentry/react";

let character = {
  controller: {},
  scale: 0.13,
  sequence: {
    idle: [{ name: "Idle", loop: true }],
    move: [{ name: "Move", loop: true }],
    charge: [
      { name: "Charge", loop: false },
      { name: "ChargingLoop", loop: true, delay: 0 },
    ],
    attack: [
      { name: "Attack", loop: false },
      { name: "Idle", loop: true, delay: 0 },
    ],
    attackOnce: [
      { name: "Charge", loop: false },
      { name: "Attack", loop: false, delay: 0 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    hit: [
      { name: "Idle", loop: false },
      { name: "Hit", loop: false, delay: 0.1 },
      { name: "Idle", loop: true, delay: 0 },
    ],
    win: [{ name: "Win", loop: true, delay: 0 }],
    lose: [{ name: "Lose", loop: false }],
  },
  event: {
    Attack: () => {
      // character.controller.setAttackTo(
      //     character.controller.targetPosition
      // )
    },
    AttackPortal: () => {
      // character.controller.setLaser(
      //     character.controller.targetPosition
      // )
    },
  },
  eventListener: {
    event: (entry, { data }) => {
      const { name } = data;
      character.event[name]?.();
    },
  },
  playSequence: (spine, sequenceData) => {
    if (!sequenceData || sequenceData.length === 0) return;
    spine.state.setAnimation(0, sequenceData[0].name, sequenceData[0].loop);
    for (let i = 1; i < sequenceData.length; i++)
      spine.state.addAnimation(
        0,
        sequenceData[i].name,
        sequenceData[i].loop,
        sequenceData[i].delay
      );
  },
  initial: async (app, path) => {
    return new Promise(async (resolve, reject) => {
      const preload = new PIXI.Loader();
      preload.add("character_VND_007", path);
      preload.load(async (loader, resources) => {
        // console.log("resources", resources);
        if (app.loader.resources) {
          app.loader.resources.character_VND_007 = resources;
        }
        // console.log("app", app);
      });
      preload.onComplete.add(async () => {
        await NeshaParticle.initial(app);
        resolve("load Nesha complete");
      });
      preload.onError.add((e) => {
        reject(e);
      });
    });
  },
  create: async (app, audioRef, path, preload) => {
    return new Promise((resolve, reject) => {
      let promises = [
        new Promise((resolve, reject) => {
          // const preload = new PIXI.Loader();
          if (!preload.resources.character_VND_007) {
            preload.add("character_VND_007", path);
          }
          preload.load(async (loader, resources) => {
            let spineData =
              resources["character_VND_007"].character_VND_007.spineData;
            if (spineData) {
              let spine = new PIXISpine.Spine(spineData);
              spine.scale.set(character.scale);
              spine.state.addListener(character.eventListener);

              let container = new PIXI.Container();
              let containerSmoke = new PIXI.Container();
              let containerLaser = new PIXI.Container();
              let containerLight = new PIXI.Container();
              let emitterController;

              container.addChild(spine);
              container.addChild(containerLight);
              container.addChild(containerLaser);
              container.addChild(containerSmoke);

              const lightingFX = new PIXI.Sprite(PIXI.Texture.WHITE);
              lightingFX.blendMode = PIXI.BLEND_MODES.ADD;
              lightingFX.visible = false;
              lightingFX.anchor.set(0.5, 1);
              lightingFX.width = 4000;
              lightingFX.height = 2000;
              containerLight.addChild(lightingFX);

              await NeshaParticle.load(
                app,
                containerSmoke,
                containerLaser
              ).then((particle) => {
                emitterController = particle;
              });

              const controller = {
                spine,
                container,
                containerSmoke,
                containerLaser,
                emitterController,
                targetPosition: {
                  x: 0,
                  y: 0,
                },
                moveSpeed: 100,
                isMoving: false,
                infoCharacter: {
                  characterName: "nesha",
                },
                setActive: (isActive) => {
                  container.visible = isActive;
                },
                setTargetPosition: (x, y) => {
                  character.controller.targetPosition = {
                    x: x,
                    y: y,
                  };
                },
                setParticleActive: (isActive) => {},
                charging: () => {},
                setFiredBulletTo: async (targetPosition) => {
                  const transitionLaserTime = 0.1;
                  const delay = 0.65;
                  containerLaser.position.set(targetPosition.x, -450);
                  containerSmoke.position.set(targetPosition.x, -60);
                  containerLaser.scale.set(2.4);
                  containerSmoke.scale.set(2.4);

                  let spawnArr = [];
                  let spawnX = [];
                  let spawnY = [];
                  const timeOfParticle = 0.5;

                  const spawnArea = 300;
                  const dieArea = 170;

                  for (let index = 0; index < 7; index++) {
                    let randomTime = Math.random() * 0.2 + 0.2;
                    let TimeToSpawn = randomTime + spawnArr[index - 1] || 0.6;
                    spawnArr.push(TimeToSpawn);
                    let randomX =
                      Math.random() * 10 > 5
                        ? Math.random() * 10 - 30
                        : Math.random() * 10 + 30;
                    spawnX.push(randomX);
                    let randomY = Math.random() * 30 - spawnArea;
                    spawnY.push(randomY);
                  }

                  function lightAnimate(x) {
                    // slope up 0 => 1 \ 1 => 2 \ slope down 2 => 3

                    const alphaLight = 0.1;
                    if (x < 1) {
                      return alphaLight * x ** 2;
                    } else if (x >= 1 && x <= 2) {
                      return alphaLight;
                    } else {
                      return alphaLight * (3 - x) ** 2;
                    }
                  }

                  new Tweener(app.ticker)
                    .insert(
                      spawnArr[0] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_1.container.visible = false;
                          emitterController.LaserParticle_1.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_1.container.visible = true;
                          emitterController.LaserParticle_1.emitter.emit = true;
                          emitterController.LaserParticle_1.emitter.update(dt);
                          emitterController.LaserParticle_1.container.position.x =
                            spawnX[0];
                          emitterController.LaserParticle_1.container.position.y =
                            spawnY[0] + pos * (dieArea - spawnY[0]);
                        }
                      })
                    )
                    .insert(
                      spawnArr[1] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_2.container.visible = false;
                          emitterController.LaserParticle_2.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_2.container.visible = true;
                          emitterController.LaserParticle_2.emitter.emit = true;
                          emitterController.LaserParticle_2.emitter.update(dt);
                          emitterController.LaserParticle_2.container.position.x =
                            spawnX[1];
                          emitterController.LaserParticle_2.container.position.y =
                            spawnY[1] + pos * (dieArea - spawnY[1]);
                        }
                      })
                    )
                    .insert(
                      spawnArr[2] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_3.container.visible = false;
                          emitterController.LaserParticle_3.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_3.container.visible = true;
                          emitterController.LaserParticle_3.emitter.emit = true;
                          emitterController.LaserParticle_3.emitter.update(dt);
                          emitterController.LaserParticle_3.container.position.x =
                            spawnX[2];
                          emitterController.LaserParticle_3.container.position.y =
                            spawnY[2] + pos * (dieArea - spawnY[2]);
                        }
                      })
                    )
                    .insert(
                      spawnArr[3] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_4.container.visible = false;
                          emitterController.LaserParticle_4.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_4.container.visible = true;
                          emitterController.LaserParticle_4.emitter.emit = true;
                          emitterController.LaserParticle_4.emitter.update(dt);
                          emitterController.LaserParticle_4.container.position.x =
                            spawnX[3];
                          emitterController.LaserParticle_4.container.position.y =
                            spawnY[3] + pos * (dieArea - spawnY[3]);
                        }
                      })
                    )
                    .insert(
                      spawnArr[4] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_5.container.visible = false;
                          emitterController.LaserParticle_5.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_5.container.visible = true;
                          emitterController.LaserParticle_5.emitter.emit = true;
                          emitterController.LaserParticle_5.emitter.update(dt);
                          emitterController.LaserParticle_5.container.position.x =
                            spawnX[4];
                          emitterController.LaserParticle_5.container.position.y =
                            spawnY[4] + pos * (dieArea - spawnY[4]);
                        }
                      })
                    )
                    .insert(
                      spawnArr[5] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_6.container.visible = false;
                          emitterController.LaserParticle_6.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_6.container.visible = true;
                          emitterController.LaserParticle_6.emitter.emit = true;
                          emitterController.LaserParticle_6.emitter.update(dt);
                          emitterController.LaserParticle_6.container.position.x =
                            spawnX[5];
                          emitterController.LaserParticle_6.container.position.y =
                            spawnY[5] + pos * (dieArea - spawnY[5]);
                        }
                      })
                    )
                    .insert(
                      spawnArr[6] + delay,
                      new Tween(timeOfParticle, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.LaserParticle_7.container.visible = false;
                          emitterController.LaserParticle_7.emitter.emit = false;
                        } else {
                          emitterController.LaserParticle_7.container.visible = true;
                          emitterController.LaserParticle_7.emitter.emit = true;
                          emitterController.LaserParticle_7.emitter.update(dt);
                          emitterController.LaserParticle_7.container.position.x =
                            spawnX[6];
                          emitterController.LaserParticle_7.container.position.y =
                            spawnY[6] + pos * (dieArea - spawnY[6]);
                        }
                      })
                    )
                    .insert(
                      0.0 + delay,
                      new Tween(3.6, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_1.container.visible = false;
                          emitterController.Laser_1.emitter.emit = false;
                        } else {
                          emitterController.Laser_1.container.visible = true;
                          emitterController.Laser_1.emitter.emit = true;
                          emitterController.Laser_1.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.0 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 0) {
                          audioRef.current._group["VND-007"]._list[0].play(
                            "cast"
                          );
                        }
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_1.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      0.1 + delay,
                      new Tween(3.4, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_2.container.visible = false;
                          emitterController.Laser_2.emitter.emit = false;
                        } else {
                          emitterController.Laser_2.container.visible = true;
                          emitterController.Laser_2.emitter.emit = true;
                          emitterController.Laser_2.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.1 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_2.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      0.2 + delay,
                      new Tween(3.2, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_3.container.visible = false;
                          emitterController.Laser_3.emitter.emit = false;
                        } else {
                          emitterController.Laser_3.container.visible = true;
                          emitterController.Laser_3.emitter.emit = true;
                          emitterController.Laser_3.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.2 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_3.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      0.3 + delay,
                      new Tween(3, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_4.container.visible = false;
                          emitterController.Laser_4.emitter.emit = false;
                        } else {
                          emitterController.Laser_4.container.visible = true;
                          emitterController.Laser_4.emitter.emit = true;
                          emitterController.Laser_4.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.3 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_4.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(3, (pos, dt) => {
                        if (pos === 0) {
                          audioRef.current._group["VND-007"]._list[0].play(
                            "beam"
                          );
                        }
                        if (pos === 1) {
                          emitterController.Smoke.container.visible = false;
                          emitterController.Smoke.emitter.emit = false;
                        } else {
                          emitterController.Smoke.container.visible = true;
                          emitterController.Smoke.emitter.emit = true;
                          emitterController.Smoke.emitter.update(dt);
                          emitterController.Smoke.container.alpha =
                            1 + pos ** (1 / 2) * -1;
                          emitterController.Smoke.container.scale.set(
                            1 + pos ** (1 / 2) * 2
                          );
                          emitterController.Smoke.container.y =
                            0 + pos ** (1 / 2) * -40;
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(4, (pos, dt) => {
                        if (pos === 1) {
                          lightingFX.visible = false;
                        } else {
                          lightingFX.visible = true;
                          lightingFX.alpha = lightAnimate(pos * 3);
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(2.8, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_5.container.visible = false;
                          emitterController.Laser_5.emitter.emit = false;
                        } else {
                          if (Math.random() * 3 < 1) {
                            emitterController.Laser_5.container.visible = false;
                          } else {
                            emitterController.Laser_5.container.visible = true;
                          }
                          emitterController.Laser_5.emitter.emit = true;
                          emitterController.Laser_5.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.5 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_5.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      0.7 + delay,
                      new Tween(2.6, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_6.container.visible = false;
                          emitterController.Laser_6.emitter.emit = false;
                        } else {
                          if (Math.random() * 3 < 1) {
                            emitterController.Laser_6.container.visible = false;
                          } else {
                            emitterController.Laser_6.container.visible = true;
                          }
                          emitterController.Laser_6.emitter.emit = true;
                          emitterController.Laser_6.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.7 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_6.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      0.9 + delay,
                      new Tween(2.4, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_7.container.visible = false;
                          emitterController.Laser_7.emitter.emit = false;
                        } else {
                          if (Math.random() * 3 < 1) {
                            emitterController.Laser_7.container.visible = false;
                          } else {
                            emitterController.Laser_7.container.visible = true;
                          }
                          emitterController.Laser_7.emitter.emit = true;
                          emitterController.Laser_7.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      0.9 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_7.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      1.1 + delay,
                      new Tween(2.2, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_8.container.visible = false;
                          emitterController.Laser_8.emitter.emit = false;
                        } else {
                          if (Math.random() * 3 < 1) {
                            emitterController.Laser_8.container.visible = false;
                          } else {
                            emitterController.Laser_8.container.visible = true;
                          }
                          emitterController.Laser_8.emitter.emit = true;
                          emitterController.Laser_8.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      1.1 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_8.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )
                    .insert(
                      1.3 + delay,
                      new Tween(2, (pos, dt) => {
                        if (pos === 1) {
                          emitterController.Laser_9.container.visible = false;
                          emitterController.Laser_9.emitter.emit = false;
                        } else {
                          emitterController.Laser_9.container.visible = true;
                          emitterController.Laser_9.emitter.emit = true;
                          emitterController.Laser_9.emitter.update(dt);
                        }
                      })
                    )
                    .insert(
                      1.3 + delay,
                      new Tween(transitionLaserTime, (pos, dt) => {
                        if (pos === 1) {
                        } else {
                          emitterController.Laser_9.container.alpha =
                            0 + pos * 1;
                        }
                      })
                    )

                    .play();

                  await character.controller.routineWait(3.5);
                },
                hurt: () => {},
                playState: (stateName) => {
                  if (character.sequence[stateName])
                    character.playSequence(
                      controller.spine,
                      character.sequence[stateName]
                    );
                },
                destroy: () => {
                  controller.spine.destroy();
                  controller.container?.destroy();
                },
                routineWait: async (second) => {
                  await new Promise((resolve) => {
                    const ticker = app.ticker;
                    let current = 0;
                    const onUpdate = (elapsedTime) => {
                      const deltaTime = (1 / ticker.FPS) * ticker.speed;
                      current += deltaTime;
                      if (current > second) {
                        ticker.remove(onUpdate);
                        resolve();
                      }
                    };
                    ticker.add(onUpdate);
                  });
                },
              };
              character.controller = controller;
              resolve(controller);
            } else {
              reject({ error: "NO_SPINE_DATA_NESHA" });
            }
          });
        }),
      ];
      Promise.all(promises)
        .then((results) => {
          const [character] = results;
          resolve(character);
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  },
};

export default character;
